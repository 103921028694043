import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100px',
  },
}));

function PositionAndPayField({ val, valChange }) {
  const classes = useStyles();
  const items = ['定位', '工資'];
  return (
    <FormControl variant="outlined" className={classes.formControl} size="small">
      <Select id="type" value={val} onChange={(e) => valChange(e.target.value)}>
        {items.map((v) => (
          <MenuItem key={v} value={v}>
            {v}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

PositionAndPayField.propTypes = {
  val: PropTypes.string,
  valChange: PropTypes.func.isRequired,
};

export default PositionAndPayField;
