import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import styles from './StatisticsPrintStyle';
import { useReactToPrint } from 'react-to-print';
import { useDispatch } from 'react-redux';
import { openErrorSnackbar } from 'redux/actions';
const useStyles = makeStyles(styles);

// import axios from 'axios';
// import { Api, AxiosConfig } from 'GlobalDefine';
// import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StatisticsPrintModal = ({ modalOpen, handleModalClose, ticketData }) => {
  const { date, location, shift, tickets, dayTotal, pages } = ticketData;
  const classes = useStyles();
  const componentRef = useRef();
  const dispatch = useDispatch();

  const [pageOne, setPageOne] = useState([]);
  const [pageTwo, setPageTwo] = useState([]);
  const [pageThree, setPageThree] = useState([]);

  const printFuc = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrint = () => {
    if (ticketData) {
      printFuc();
    } else {
      dispatch(openErrorSnackbar('錯誤:資料讀取失敗！'));
    }
  };

  useEffect(() => {
    if (pages) {
      if (pages == 1) setPageOne(tickets);
      if (pages == 2) {
        setPageOne(tickets.slice(0, 17));
        setPageTwo(tickets.slice(17));
      }
      if (pages == 3) {
        setPageOne(tickets.slice(0, 17));
        setPageTwo(tickets.slice(17, 45));
        setPageThree(tickets.slice(45));
      }
    }
  }, []);

  return (
    <Dialog
      id="clientSignModal"
      className={classes.fullScreenModal}
      fullScreen={true}
      maxWidth="md"
      open={modalOpen}
      onClose={handleModalClose}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.modalTitle}>預覽列印</DialogTitle>
      <DialogContent ref={componentRef} className={classes.printPage}>
        <Grid container spacing={2} className={classes.sheetTitle}>
          <Grid item md={5} xs={5}>
            輪胎大師 - {location}館
          </Grid>
          <Grid item md={5} xs={5} align="right">
            {`${date.get('year') - 1911}年 ${date.get('month') + 1}月 ${date.get('date')}日`}
          </Grid>
          <Grid item md={2} xs={2} align="right">
            {`${shift}`}
          </Grid>
        </Grid>
        <Table className={classes.printTable}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.borderCell} align="center" style={{ width: '20%' }}>
                工單編號
              </TableCell>
              <TableCell className={classes.borderCell} align="center" style={{ width: '14%' }}>
                車號
              </TableCell>
              <TableCell
                className={classes.borderCell}
                align="center"
                style={{ width: '14%' }}
                colSpan={2}
              >
                現金
              </TableCell>
              <TableCell
                className={classes.borderCell}
                align="center"
                style={{ width: '7%', color: '#FF0000' }}
              >
                刷卡
              </TableCell>
              <TableCell
                className={classes.borderCell}
                align="center"
                style={{ width: '7%', color: '#FF0000' }}
              >
                匯款
              </TableCell>
              <TableCell className={classes.borderCell} align="center" style={{ width: '14%' }}>
                保固編號/金額
              </TableCell>
              <TableCell className={classes.borderCell} align="center" style={{ width: '12%' }}>
                材料廠商
              </TableCell>
              <TableCell
                className={classes.borderCell}
                align="center"
                style={{ width: '12%' }}
                colSpan={2}
              >
                輪胎
              </TableCell>
            </TableRow>
            {pageOne.map((v, i) => {
              return (
                <TableRow key={i}>
                  <TableCell className={classes.borderCell} align="center">
                    {v.ticketNo}
                  </TableCell>
                  <TableCell
                    className={classes.borderCell}
                    align="center"
                    style={{ color: '#FF0000' }}
                  >
                    {v.licenseNo ? v.licenseNo : '-'}
                  </TableCell>
                  <TableCell className={classes.borderCell} align="center" style={{ width: '7%' }}>
                    {v.cash}
                  </TableCell>
                  <TableCell className={classes.borderCell} align="center" style={{ width: '7%' }}>
                    {v.status == '作廢' ? '作廢' : ''}
                  </TableCell>
                  <TableCell className={classes.borderCell} align="center">
                    {v.credit_card}
                  </TableCell>
                  <TableCell className={classes.borderCell} align="center">
                    {v.remit}
                  </TableCell>
                  <TableCell
                    className={classes.borderCell}
                    align="center"
                    style={{ color: '#FF0000', backgroundColor: '#FFEB9C' }}
                  >
                    {'/'}
                  </TableCell>
                  <TableCell className={classes.borderCell} align="center"></TableCell>
                  <TableCell
                    className={classes.borderCell}
                    align="center"
                    style={{ color: '#0606E6' }}
                  >
                    {v.tires}
                  </TableCell>
                  <TableCell
                    className={classes.borderCell}
                    align="center"
                    style={{ backgroundColor: '#FDE9D9', width: '9%' }}
                  ></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                className={classes.borderCell}
                align="left"
                colSpan={8}
                style={{ padding: '10px 5px' }}
              >
                {'Google評論數:____ （內接班人員檢查.簽名）'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.borderCell} align="center" style={{ width: '20%' }}>
                日領
              </TableCell>
              <TableCell className={classes.borderCell} align="center" style={{ width: '7%' }}>
                金額
              </TableCell>
              <TableCell className={classes.borderCell} align="center" style={{ width: '21%' }}>
                廠商
              </TableCell>
              <TableCell className={classes.borderCell} align="center" style={{ width: '7%' }}>
                金額
              </TableCell>
              <TableCell className={classes.borderCell} align="center" style={{ width: '21%' }}>
                廠商
              </TableCell>
              <TableCell className={classes.borderCell} align="center" style={{ width: '7%' }}>
                金額
              </TableCell>
              <TableCell
                className={classes.borderCell}
                align="center"
                style={{ width: '7%' }}
              ></TableCell>
              <TableCell
                className={classes.borderCell}
                align="center"
                style={{ width: '10%' }}
              ></TableCell>
            </TableRow>
            {[
              { item: '營收:', amount: dayTotal.total },
              { item: '支出:', amount: '' },
              { item: '刷卡:', amount: dayTotal.credit_card },
              { item: '匯款:', amount: dayTotal.remit },
              { item: '月結:', amount: dayTotal.monthly },
              { item: '現金:', amount: '' },
              { item: '保固:', amount: '' },
            ].map((v, i) => {
              return (
                <TableRow key={i}>
                  <TableCell
                    className={classes.borderCell}
                    align="center"
                    style={{ width: '20%' }}
                  ></TableCell>
                  <TableCell
                    className={classes.borderCell}
                    align="center"
                    style={{ width: '7%' }}
                  ></TableCell>
                  <TableCell
                    className={classes.borderCell}
                    align="center"
                    style={{ width: '21%' }}
                  ></TableCell>
                  <TableCell
                    className={classes.borderCell}
                    align="center"
                    style={{ width: '7%' }}
                  ></TableCell>
                  <TableCell
                    className={classes.borderCell}
                    align="center"
                    style={{ width: '21%' }}
                  ></TableCell>
                  <TableCell
                    className={classes.borderCell}
                    align="center"
                    style={{ width: '7%' }}
                  ></TableCell>
                  <TableCell
                    className={classes.borderCell}
                    align="left"
                    style={{
                      width: '7%',
                      color: `${v.item == '現金:' ? '#FF0000' : '#000000'}`,
                      backgroundColor: `${v.item == '保固:' ? '#FFEB9C' : ''}`,
                    }}
                  >
                    {v.item}
                  </TableCell>
                  <TableCell className={classes.borderCell} align="center" style={{ width: '10%' }}>
                    {v.amount}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell
                className={classes.borderCell}
                align="left"
                style={{ width: '83%', color: '#008000', backgroundColor: '#C6EFCE' }}
                colSpan={6}
              >
                上班人員簽名:
              </TableCell>
              <TableCell
                className={classes.borderCell}
                align="left"
                style={{ width: '17%', color: '#800080' }}
                colSpan={2}
              >
                總成本:
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container spacing={2} className={classes.sheetMiddle}>
          <Grid item md xs>
            新胎:
          </Grid>
          <Grid item md xs>
            中古胎:
          </Grid>
          <Grid item md xs>
            材料:
          </Grid>
          <Grid item md xs>
            車資:
          </Grid>
          <Grid item md xs>
            APP:
          </Grid>
          <Grid item md xs>
            入庫:
          </Grid>
          <Grid item md xs>
            稅%:
          </Grid>
          <Grid item md xs>
            出保:
          </Grid>
        </Grid>

        <div style={{ pageBreakBefore: 'always', display: `${pages > 1 ? 'block' : 'none'}` }}>
          <Table className={classes.printTable}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.borderCell} align="center" style={{ width: '20%' }}>
                  工單編號
                </TableCell>
                <TableCell className={classes.borderCell} align="center" style={{ width: '14%' }}>
                  車號
                </TableCell>
                <TableCell
                  className={classes.borderCell}
                  align="center"
                  style={{ width: '14%' }}
                  colSpan={2}
                >
                  現金
                </TableCell>
                <TableCell
                  className={classes.borderCell}
                  align="center"
                  style={{ width: '7%', color: '#FF0000' }}
                >
                  刷卡
                </TableCell>
                <TableCell
                  className={classes.borderCell}
                  align="center"
                  style={{ width: '7%', color: '#FF0000' }}
                >
                  匯款
                </TableCell>
                <TableCell className={classes.borderCell} align="center" style={{ width: '14%' }}>
                  保固編號/金額
                </TableCell>
                <TableCell className={classes.borderCell} align="center" style={{ width: '14%' }}>
                  材料廠商
                </TableCell>
                <TableCell
                  className={classes.borderCell}
                  align="center"
                  style={{ width: '10%' }}
                  colSpan={2}
                >
                  輪胎
                </TableCell>
              </TableRow>
              {pageTwo.map((v, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell className={classes.borderCell} align="center">
                      {v.ticketNo}
                    </TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ color: '#FF0000' }}
                    >
                      {v.licenseNo ? v.licenseNo : '-'}
                    </TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ width: '7%' }}
                    >
                      {v.cash}
                    </TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ width: '7%' }}
                    >
                      {v.status == '作廢' ? '作廢' : ''}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.credit_card}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.remit}
                    </TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ color: '#FF0000', backgroundColor: '#FFEB9C' }}
                    >
                      {'/'}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center"></TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ color: '#0606E6' }}
                    >
                      {v.tires}
                    </TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ backgroundColor: '#FDE9D9', width: '9%' }}
                    ></TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Grid container spacing={2} className={classes.sheetMiddle}>
            <Grid item md xs>
              新胎:
            </Grid>
            <Grid item md xs>
              中古胎:
            </Grid>
            <Grid item md xs>
              材料:
            </Grid>
            <Grid item md xs>
              車資:
            </Grid>
            <Grid item md xs>
              APP:
            </Grid>
            <Grid item md xs>
              入庫:
            </Grid>
            <Grid item md xs>
              稅%:
            </Grid>
            <Grid item md xs>
              出保:
            </Grid>
          </Grid>
        </div>

        <div style={{ pageBreakBefore: 'always', display: `${pages == 3 ? 'block' : 'none'}` }}>
          <Table className={classes.printTable}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.borderCell} align="center" style={{ width: '20%' }}>
                  工單編號
                </TableCell>
                <TableCell className={classes.borderCell} align="center" style={{ width: '14%' }}>
                  車號
                </TableCell>
                <TableCell
                  className={classes.borderCell}
                  align="center"
                  style={{ width: '14%' }}
                  colSpan={2}
                >
                  現金
                </TableCell>
                <TableCell
                  className={classes.borderCell}
                  align="center"
                  style={{ width: '7%', color: '#FF0000' }}
                >
                  刷卡
                </TableCell>
                <TableCell
                  className={classes.borderCell}
                  align="center"
                  style={{ width: '7%', color: '#FF0000' }}
                >
                  匯款
                </TableCell>
                <TableCell className={classes.borderCell} align="center" style={{ width: '14%' }}>
                  保固編號/金額
                </TableCell>
                <TableCell className={classes.borderCell} align="center" style={{ width: '14%' }}>
                  材料廠商
                </TableCell>
                <TableCell
                  className={classes.borderCell}
                  align="center"
                  style={{ width: '10%' }}
                  colSpan={2}
                >
                  輪胎
                </TableCell>
              </TableRow>
              {pageThree.map((v, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell className={classes.borderCell} align="center">
                      {v.ticketNo}
                    </TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ color: '#FF0000' }}
                    >
                      {v.licenseNo ? v.licenseNo : '-'}
                    </TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ width: '7%' }}
                    >
                      {v.cash}
                    </TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ width: '7%' }}
                    >
                      {v.status == '作廢' ? '作廢' : ''}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.credit_card}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.remit}
                    </TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ color: '#FF0000', backgroundColor: '#FFEB9C' }}
                    >
                      {'/'}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center"></TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ color: '#0606E6' }}
                    >
                      {v.tires}
                    </TableCell>
                    <TableCell
                      className={classes.borderCell}
                      align="center"
                      style={{ backgroundColor: '#FDE9D9', width: '9%' }}
                    ></TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Grid container spacing={2} className={classes.sheetMiddle}>
            <Grid item md xs>
              新胎:
            </Grid>
            <Grid item md xs>
              中古胎:
            </Grid>
            <Grid item md xs>
              材料:
            </Grid>
            <Grid item md xs>
              車資:
            </Grid>
            <Grid item md xs>
              APP:
            </Grid>
            <Grid item md xs>
              入庫:
            </Grid>
            <Grid item md xs>
              稅%:
            </Grid>
            <Grid item md xs>
              出保:
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleModalClose}>
          取消
        </Button>
        <Button color="primary" variant="contained" onClick={handlePrint}>
          列印
        </Button>
      </DialogActions>
    </Dialog>
  );
};

StatisticsPrintModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  ticketData: PropTypes.object,
};

export default StatisticsPrintModal;
