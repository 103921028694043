import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
// import axios from 'axios';
// import { Api, AxiosConfig } from 'GlobalDefine';

// import { useDispatch } from 'react-redux';
// import { openErrorSnackbar } from 'redux/actions';

import InputTextField from '../../components/Ticket/InputTextField';
import PaymentTabLabels from '../../components/Ticket/PaymentTabLabels';

import styles from './TabStyle';

const useStyles = makeStyles(styles);

const PaymentTab = (props) => {
  const classes = useStyles();
  const { ticketData, paymentInfo, setTicketData } = props;
  const [labels, setLabels] = useState({
    cash: false,
    credit_card: false,
    remit: false,
    monthly: false,
    gift_voucher: false,
    gov_voucher: false,
  });
  // const dispatch = useDispatch();

  const updatePaymentInfo = useCallback(
    (method, money, action) => {
      let newData = { ...ticketData };
      if (action === 'edit') {
        let index = paymentInfo.findIndex((v) => v.method === method);
        newData.paymentInfo[index].money = money;
        setTicketData(newData);
      } else if (action === 'add') {
        let findOne = paymentInfo.find((v) => v.method === method);
        if (!findOne) {
          newData.paymentInfo.push({ method, money });
          setTicketData(newData);
        }
      } else if (action === 'remove') {
        let findOne = paymentInfo.find((v) => v.method === method);
        if (findOne) {
          const newArr = paymentInfo.filter((el) => el.method !== method);
          newData.paymentInfo = [...newArr];
          setTicketData(newData);
        }
      }
    },
    [ticketData],
  );

  const transferLabel = (label) => {
    switch (label) {
      case 'cash':
        return '現金';
      case 'credit_card':
        return '信用卡';
      case 'remit':
        return '轉帳';
      case 'monthly':
        return '月結';
      case 'gift_voucher':
        return '禮券';
      case 'gov_voucher':
        return '消費券';
      default:
        break;
    }
  };

  useEffect(() => {
    // 勾選labels產生input
    let isCheck = [];
    let disCheck = [];
    for (let key in labels) {
      labels[key] ? isCheck.push(key) : disCheck.push(key);
    }
    isCheck.forEach((v) => {
      updatePaymentInfo(v, '', 'add');
    });
    disCheck.forEach((v) => {
      updatePaymentInfo(v, '', 'remove');
    });
  }, [labels]);

  return (
    <>
      {ticketData && paymentInfo ? (
        <div className={classes.content}>
          <div className={classes.main}>
            <div className={classes.title}>{'付款方式'}</div>

            <div className={classes.column}>
              <PaymentTabLabels setLabelFunc={setLabels} />
            </div>
            {paymentInfo.map((v, i) => {
              return v.method ? (
                <div className={classes.appColumn} key={i}>
                  <div className={classes.fieldTitle}>{`${transferLabel(v.method)}：`}</div>
                  <InputTextField
                    val={v.money}
                    valChange={(money) => updatePaymentInfo(v.method, money, 'edit')}
                    label={transferLabel(v.method)}
                  />
                </div>
              ) : null;
            })}
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative', marginLeft: '50%', left: '-20px' }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

PaymentTab.propTypes = {
  ticketData: PropTypes.object,
  paymentInfo: PropTypes.array,
  setTicketData: PropTypes.func,
};

export default PaymentTab;
