import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  Slide,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import SignaturePad from 'react-signature-canvas';
import styles from './ModalStyle';
import { useDispatch } from 'react-redux';
import { openSuccessInsertSnackbar, openErrorSnackbar } from 'redux/actions';
const useStyles = makeStyles(styles);

import axios from 'axios';
import { Api, AxiosConfig } from 'GlobalDefine';
// import dayjs from 'dayjs';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClientSignModal = ({
  modalOpen,
  handleModalClose,
  ticketData,
  setTicketData,
  setTicketDone,
}) => {
  const {
    basicInfo,
    clientInfo,
    tireInfo,
    repairInfo,
    totalInfo,
    paymentInfo,
    pAndPInfo,
    ticketType,
  } = ticketData;
  const classes = useStyles();
  const dispatch = useDispatch();
  const sigCanvas = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [showSignContent, setShowSignContent] = useState(false);
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    const imgUrl = sigCanvas.current.getCanvas().toDataURL('image/png', 1);
    setImageURL(imgUrl);
    let newData = { ...ticketData };
    newData.signImage = imgUrl;
    setTicketData(newData);
  };

  const createFirstTicket = async () => {
    let sendData = { ...ticketData };
    sendData.tireInfo = sendData.tireInfo.filter((el) => el.sell);
    try {
      const res = await axios.post(`${Api.Ticket.CreateFirst}`, sendData, AxiosConfig.General);
      if (res.status === 200) {
        let newData = { ...ticketData };
        newData.basicInfo.ticketNo = res.data;
        setTicketData(newData);
        dispatch(openSuccessInsertSnackbar());
      } else {
        dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      }
    } catch (error) {
      dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      console.log('error', error);
    }
  };

  const createRestTicket = async (serialNo) => {
    let sendData = { ...ticketData };
    sendData.tireInfo = sendData.tireInfo.filter((el) => el.sell);
    try {
      const res = await axios.post(
        `${Api.Ticket.CreateRest}/${serialNo}`,
        sendData,
        AxiosConfig.General,
      );
      if (res.status === 200) {
        let newData = { ...ticketData };
        newData.basicInfo.ticketNo = res.data;
        setTicketData(newData);
        // 更新庫存為預留或售出
        const tireNumbers = sendData.tireInfo.map((el) => el.number);
        const updateTo = ticketType == 'Prepay' ? '預留' : '售出';
        for (const val of tireNumbers) {
          if (val && val !== '') {
            await axios.post(
              `${Api.Tires.UpdateTireStatus}`,
              { number: val, status: updateTo, client: res.data },
              AxiosConfig.General,
            );
          }
        }
        dispatch(openSuccessInsertSnackbar());
      } else {
        dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      }
    } catch (error) {
      dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      console.log('error', error);
    }
  };

  const checkTodayTicket = async () => {
    try {
      const res = await axios.get(
        `${Api.Ticket.CheckIsTodayFirst}/${basicInfo.date}/${basicInfo.location}`,
        AxiosConfig.General,
      );
      if (res.status === 200) {
        return res.data == 'Not Found.' ? true : false;
      } else {
        dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      }
    } catch (error) {
      dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      console.log('error', error);
    }
  };

  const checkTicketDuplicate = async () => {
    try {
      const res = await axios.get(
        `${Api.Ticket.CheckDuplicate}/${basicInfo.ticketNo}`,
        AxiosConfig.General,
      );
      if (res.status === 200) {
        return res.data;
      } else {
        dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      }
    } catch (error) {
      dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      console.log('error', error);
    }
  };

  const createNewClient = async () => {
    try {
      const res = await axios.post(
        `${Api.Client.CreateClientInfo}`,
        clientInfo,
        AxiosConfig.General,
      );
      if (res.status === 200) {
        console.log('res', res);
      } else {
        dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      }
    } catch (error) {
      dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      console.log('error', error);
    }
  };

  const updateExistClient = async () => {
    try {
      const res = await axios.post(
        `${Api.Client.UpdateClientInfo}`,
        clientInfo,
        AxiosConfig.General,
      );
      if (res.status === 200) {
        console.log('res', res);
      } else {
        dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      }
    } catch (error) {
      dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      console.log('error', error);
    }
  };

  const getNextTicketID = async () => {
    try {
      const res = await axios.get(
        `${Api.Ticket.GetLastestID}/${basicInfo.date}/${basicInfo.location}`,
        AxiosConfig.General,
      );
      if (res.status === 200) {
        return res.data == 'Not Found.' ? '' : parseInt(res.data) + 1;
      } else {
        dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      }
    } catch (error) {
      dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      console.log('error', error);
    }
  };

  const checkBasicInfoDone = () => {
    const val = Object.values(basicInfo);
    const hasEmptyStr = val.some((v) => v === '');
    if (hasEmptyStr) return true;
    if (basicInfo.repairTech.length == 0) return true;
    return false;
  };
  // const checkClientInfoDone = () => {
  //   const val = Object.values(clientInfo);
  //   return val.some((v) => v === '');
  // };

  const sendResult = async () => {
    const basicNotDone = checkBasicInfoDone();
    // const clientNotDone = checkClientInfoDone();
    if (basicNotDone) return dispatch(openErrorSnackbar('請先完成基本資料！'));
    // if (clientNotDone) return dispatch(openErrorSnackbar('請先完成客戶資料！'));
    if (!imageURL) return dispatch(openErrorSnackbar('請先完成簽名！'));

    setIsLoading(true);
    if (!clientInfo.isExist && clientInfo.licenseNo) await createNewClient();
    if (clientInfo.isExist && clientInfo.licenseNo) await updateExistClient();
    const isDuplicate = await checkTicketDuplicate();
    if (isDuplicate) return dispatch(openErrorSnackbar('單號重複，請再重新選擇一次地點！'));
    const isFirst = await checkTodayTicket();
    if (isFirst) {
      await createFirstTicket();
    } else {
      const getNextNo = await getNextTicketID();
      if (!getNextNo) return dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      await createRestTicket(getNextNo);
    }
    setIsLoading(false);
    handleModalClose();
    setImageURL('');
    setTicketDone(true);
  };

  const switchContent = () => setShowSignContent(!showSignContent);
  const transferLabel = (label) => {
    switch (label) {
      case 'cash':
        return '現金';
      case 'credit_card':
        return '信用卡';
      case 'remit':
        return '轉帳';
      case 'monthly':
        return '月結';
      case 'gift_voucher':
        return '禮券';
      case 'gov_voucher':
        return '消費券';
      default:
        break;
    }
  };
  const transSize = (fullSize) => {
    const arr = fullSize.split('/');
    return `${arr[0]} / ${arr[1]} R ${arr[2]}`;
  };

  return (
    <Dialog
      id="clientSignModal"
      className={classes.fullScreenModal}
      fullScreen={true}
      maxWidth="md"
      open={modalOpen}
      onClose={handleModalClose}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.modalTitle}>客戶簽名</DialogTitle>
      {showSignContent ? (
        <DialogContent>
          <DialogContentText>
            請在下方方框中簽名：
            <Button
              color="inherit"
              variant="contained"
              onClick={clear}
              className={classes.titleBtn}
            >
              清除
            </Button>
            <Button color="primary" variant="contained" onClick={save} className={classes.titleBtn}>
              完成
            </Button>
          </DialogContentText>
          <FormControl className={classes.signPad} variant="standard">
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{
                className: 'signatureCanvas',
              }}
            />
          </FormControl>

          <DialogContentText
            style={{
              marginTop: '30px',
            }}
          >
            預覽簽名：
          </DialogContentText>
          {imageURL ? (
            <img
              src={imageURL}
              alt="my signature"
              style={{
                display: 'block',
                margin: '10px 50px auto',
                border: '1px solid black',
                width: '600px',
                height: '300px',
              }}
            />
          ) : null}
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>工單明細：</DialogContentText>
          <Grid container spacing={2} className={classes.statementGrid}>
            <Grid item md={12} xs={12}>
              <div className={classes.statementTitle}>基本資料</div>
            </Grid>
            <Grid item md={5} xs={5}>
              <span className={classes.statementName}>服務地點：</span>
              <span className={classes.statementValue}>{basicInfo.location}</span>
            </Grid>
            <Grid item md={6} xs={6}>
              <span className={classes.statementName}>工單單號：</span>
              <span className={classes.statementValue}>{basicInfo.ticketNo}</span>
            </Grid>
            <Grid item md={5} xs={5}>
              <span className={classes.statementName}>日期：</span>
              <span className={classes.statementValue}>{basicInfo.date}</span>
            </Grid>
            <Grid item md={6} xs={6}>
              <span className={classes.statementName}>結帳技師：</span>
              <span className={classes.statementValue}>
                {basicInfo.checkoutTechID}({basicInfo.checkoutTechName})
              </span>
            </Grid>
            <Grid item md={12} xs={12}>
              <span className={classes.statementName}>維修技師：</span>
              {basicInfo.repairTech.map((v, i) => {
                return (
                  <span key={i} className={classes.statementValue}>
                    {v.techID}-{v.techName}
                  </span>
                );
              })}
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.statementGrid}>
            <Grid item md={12} xs={12}>
              <div className={classes.statementTitle}>客戶資料</div>
            </Grid>
            <Grid item md={6} xs={6}>
              <span className={classes.statementName}>車牌：</span>
              <span className={classes.statementValue}>{clientInfo.licenseNo}</span>
            </Grid>
            <Grid item md={6} xs={6}>
              <span className={classes.statementName}>姓名：</span>
              <span className={classes.statementValue}>{clientInfo.clientName}</span>
            </Grid>
            <Grid item md={6} xs={6}>
              <span className={classes.statementName}>電話：</span>
              <span className={classes.statementValue}>{clientInfo.clientPhone}</span>
            </Grid>
            <Grid item md={6} xs={6}>
              <span className={classes.statementName}>廠牌：</span>
              <span className={classes.statementValue}>{clientInfo.carBrand}</span>
            </Grid>
            <Grid item md={6} xs={6}>
              <span className={classes.statementName}>型號：</span>
              <span className={classes.statementValue}>{clientInfo.carType}</span>
            </Grid>
            <Grid item md={6} xs={6}>
              <span className={classes.statementName}>里程：</span>
              <span className={classes.statementValue}>{clientInfo.mileage}</span>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <div className={classes.statementTitle}>輪胎更換項目</div>
          </Grid>
          {tireInfo
            .filter((el) => el.sell)
            .map((v, i) => {
              return (
                <Grid container spacing={1} key={i} className={classes.statementItem}>
                  <Grid item md={2} xs={2}>
                    <span className={classes.statementName}>{i + 1}.</span>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>類型：</span>
                    <span className={classes.statementValue}>{v.tireType}</span>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>
                      {v.tireType == '中古胎' ? '編號' : '廠商'}：
                    </span>
                    <span className={classes.statementValue}>
                      {v.tireType == '中古胎' ? v.number : v.brand}
                    </span>
                  </Grid>
                  <Grid item md={2} xs={2}></Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>尺寸：</span>
                    <span className={classes.statementValue}>{transSize(v.fullSize)}</span>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>庫位：</span>
                    <span className={classes.statementValue}>{v.price}</span>
                  </Grid>
                  <Grid item md={2} xs={2}></Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>售價：</span>
                    <span className={classes.statementValue}>{v.sell}</span>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>備註：</span>
                    <span className={classes.statementValue}>{v.memo}</span>
                  </Grid>
                </Grid>
              );
            })}

          <Grid item md={12} xs={12}>
            <div className={classes.statementTitle}>維修保養項目</div>
          </Grid>
          {repairInfo.length ? (
            repairInfo.map((v) => {
              if (v.name) {
                return (
                  <Grid container spacing={1} key={v.index} className={classes.statementItem}>
                    <Grid item md={2} xs={2}>
                      <span className={classes.statementName}>{v.index + 1}.</span>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <span className={classes.statementName}>項目：</span>
                      <span className={classes.statementValue}>{v.name}</span>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <span className={classes.statementName}>數量：</span>
                      <span className={classes.statementValue}>{v.amount}</span>
                    </Grid>
                    <Grid item md={2} xs={2}></Grid>
                    <Grid item md={5} xs={5}>
                      <span className={classes.statementName}>價格：</span>
                      <span className={classes.statementValue}>{v.price}</span>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <span className={classes.statementName}>備註：</span>
                      <span className={classes.statementValue}>{v.memo}</span>
                    </Grid>
                  </Grid>
                );
              }
            })
          ) : (
            <span className={classes.statementValue}>無資料</span>
          )}

          <Grid item md={12} xs={12}>
            <div className={classes.statementTitle}>定位工資</div>
          </Grid>
          {pAndPInfo.length ? (
            pAndPInfo.map((v) => {
              if (v.type) {
                return (
                  <Grid container spacing={1} key={v.index} className={classes.statementItem}>
                    <Grid item md={2} xs={2}>
                      <span className={classes.statementName}>{v.index + 1}.</span>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <span className={classes.statementName}>類型：</span>
                      <span className={classes.statementValue}>{v.type}</span>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <span className={classes.statementName}>項目：</span>
                      <span className={classes.statementValue}>{v.content}</span>
                    </Grid>
                    <Grid item md={2} xs={2}></Grid>
                    <Grid item md={5} xs={5}>
                      <span className={classes.statementName}>價格：</span>
                      <span className={classes.statementValue}>{v.price}</span>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <span className={classes.statementName}>數量：</span>
                      <span className={classes.statementValue}>{v.amount}</span>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <span className={classes.statementName}>備註：</span>
                      <span className={classes.statementValue}>{v.memo}</span>
                    </Grid>
                  </Grid>
                );
              }
            })
          ) : (
            <span className={classes.statementValue}>無資料</span>
          )}

          <Grid container spacing={2} className={classes.statementGrid}>
            <Grid item md={12} xs={12}>
              <div className={classes.statementTitle}>金額總計</div>
            </Grid>
            <Grid item md={5} xs={5}>
              <span className={classes.statementName}>統編：</span>
              <span className={classes.statementValue}>
                {totalInfo.isInvoice == '需要發票' ? totalInfo.taxID : '無'}
              </span>
            </Grid>
            <Grid item md={6} xs={6}>
              <span className={classes.statementName}>公司行號：</span>
              <span className={classes.statementValue}>
                {totalInfo.isInvoice == '需要發票' ? totalInfo.company : '無'}
              </span>
            </Grid>
            <Grid item md={5} xs={5}>
              <span className={classes.statementName}>收件人：</span>
              <span className={classes.statementValue}>
                {totalInfo.isInvoice == '需要發票' ? totalInfo.receiver : '無'}
              </span>
            </Grid>
            <Grid item md={6} xs={6}>
              <span className={classes.statementName}>發票地址：</span>
              <span className={classes.statementValue}>
                {totalInfo.isInvoice == '需要發票' ? totalInfo.address : '無'}
              </span>
            </Grid>
            <Grid item md={5} xs={5}>
              <span className={classes.statementName}>金額加總：</span>
              <span className={classes.statementValue}>{totalInfo.totalPrice}</span>
            </Grid>
            <Grid item md={6} xs={6}>
              <span className={classes.statementName}>不印工單：</span>
              <span className={classes.statementValue}>{totalInfo.noPrint ? '是' : '否'}</span>
            </Grid>
            {ticketType == 'Prepay' ? (
              <Grid item md={5} xs={5}>
                <span className={classes.statementName}>預付訂金：</span>
                <span className={classes.statementValue}>{totalInfo.prepayAmount}</span>
              </Grid>
            ) : null}
          </Grid>

          <Grid item md={12} xs={12}>
            <div className={classes.statementTitle}>付款方式</div>
          </Grid>
          {paymentInfo.length ? (
            <Grid container spacing={2} className={classes.statementItem}>
              {paymentInfo.map((v, i) => {
                return (
                  <Grid item key={i} md={5} xs={5}>
                    <span className={classes.statementName}>{transferLabel(v.method)}：</span>
                    <span className={classes.statementValue}>{v.money}</span>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <span className={classes.statementValue}>無資料</span>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <div style={isLoading ? { display: 'flex' } : { display: 'none' }}>
          <CircularProgress />
        </div>
        <Button color="secondary" variant="contained" onClick={handleModalClose}>
          取消
        </Button>
        <Button color="primary" variant="contained" onClick={switchContent}>
          {showSignContent ? '回到明細' : '客戶簽名'}
        </Button>
        <Button variant="contained" onClick={sendResult} className={classes.submitBtn}>
          送出
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ClientSignModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  ticketData: PropTypes.object,
  setTicketData: PropTypes.func,
  setTicketDone: PropTypes.func,
};

export default ClientSignModal;
