import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grid,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import { Api, AxiosConfig } from 'GlobalDefine';

// import { useDispatch } from 'react-redux';
// import { openErrorSnackbar } from 'redux/actions';
import styles from './TabStyle';

const useStyles = makeStyles(styles);

const RepairTab = (props) => {
  const classes = useStyles();
  const { ticketData, repairInfo, setTicketData } = props;
  // const dispatch = useDispatch();

  const updateRepairInfo = useCallback(
    (v, index) => {
      let newData = { ...ticketData };
      const currentItem = repairInfo[index];
      newData.repairInfo[index] = { ...currentItem, ...v };
      setTicketData(newData);
    },
    [ticketData],
  );

  const addBlankRow = () => {
    let newTicketData = { ...ticketData };
    let last = [...repairInfo].pop();
    newTicketData.repairInfo = [
      ...repairInfo,
      {
        index: last ? last.index + 1 : 0,
        name: '',
        amount: '',
        price: '',
        memo: '',
      },
    ];
    setTicketData(newTicketData);
  };

  const removeLastRow = () => {
    let newTicketData = { ...ticketData };
    let last = [...repairInfo].pop();
    newTicketData.repairInfo = repairInfo.filter((el) => el.index !== last.index);
    setTicketData(newTicketData);
  };

  useEffect(() => {
    // 初始化預設一個
  }, []);

  return (
    <>
      {ticketData && repairInfo ? (
        <div className={classes.content}>
          <div className={classes.main}>
            <div className={classes.title}>{'維修保養項目'}</div>
            <div className={classes.column}></div>
            <div className={classes.cardColumn}>
              <TableContainer component={Paper} elevation={4} style={{ marginTop: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.headerCell} align="center">
                        名稱
                      </TableCell>
                      <TableCell className={classes.headerCell} align="center">
                        數量
                      </TableCell>
                      <TableCell className={classes.headerCell} align="center">
                        價格
                      </TableCell>
                      <TableCell className={classes.headerCell} align="center">
                        備註
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {repairInfo.map((v, i) => (
                      <TableRow key={i} className={classes.bodyRow}>
                        <TableCell className={classes.bodyCell} align="center">
                          <TextField
                            className={classes.cellText}
                            variant="outlined"
                            size="small"
                            value={v.name}
                            onChange={(e) => updateRepairInfo({ name: e.target.value }, v.index)}
                          />
                        </TableCell>
                        <TableCell className={classes.bodyCell} align="center">
                          <TextField
                            className={classes.cellText}
                            variant="outlined"
                            size="small"
                            value={v.amount}
                            onChange={(e) => updateRepairInfo({ amount: e.target.value }, v.index)}
                            multiline
                          />
                        </TableCell>
                        <TableCell className={classes.bodyCell} align="center">
                          <TextField
                            className={classes.cellText}
                            variant="outlined"
                            size="small"
                            value={v.price}
                            onChange={(e) => updateRepairInfo({ price: e.target.value }, v.index)}
                          />
                        </TableCell>
                        <TableCell className={classes.bodyCell} align="center">
                          <TextField
                            className={classes.cellText}
                            variant="outlined"
                            size="small"
                            value={v.memo}
                            onChange={(e) => updateRepairInfo({ memo: e.target.value }, v.index)}
                            multiline
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className={classes.tableControlBtns}>
              <Grid container spacing={2}>
                <Grid item md={5} xs={5} align="center">
                  <IconButton className={classes.smallRemoveBtn} onClick={removeLastRow}>
                    <Remove fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item md={2} xs={2} align="center"></Grid>
                <Grid item md={5} xs={5} align="center">
                  <IconButton className={classes.smallAddBtn} onClick={addBlankRow}>
                    <Add fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative', marginLeft: '50%', left: '-20px' }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

RepairTab.propTypes = {
  ticketData: PropTypes.object,
  setTicketData: PropTypes.func,
  repairInfo: PropTypes.array,
};

export default RepairTab;
