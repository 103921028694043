import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import { ListAlt } from '@material-ui/icons';
import TicketDetailModal from '../../components/Ticket/TicketDetailModal';

import styles from './ModalStyle';

const useStyles = makeStyles(styles);

const HistoryModal = ({ modalOpen, handleModalClose, historyRecord }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const tableRef = useRef();

  const [openDialog, setOpenDialog] = useState(false);
  const [clickTicketData, setClickTicketData] = useState(null);

  const viewDetail = (value) => {
    handleOpen();
    setClickTicketData({ ...value });
  };
  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchReset = () => {
    handleModalClose();
    setPage(0);
  };

  return (
    <Dialog
      className={classes.modal}
      fullWidth={true}
      maxWidth="md"
      open={modalOpen}
      onClose={handleModalClose}
    >
      <DialogTitle>客戶歷史紀錄</DialogTitle>
      <DialogContent>
        <DialogContentText>
          客戶車牌：{historyRecord.length ? historyRecord[0].clientLicenseNo : ''}
        </DialogContentText>
        <TableContainer component={Paper} elevation={4} style={{ marginTop: '20px' }}>
          <Table ref={tableRef}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell} align="center">
                  {'工單日期'}
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  {'工單號碼'}
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  {'地點'}
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  {'金額'}
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  {'結帳技師'}
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  {'詳細'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyRecord.length ? (
                historyRecord
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((v, i) => (
                    <TableRow key={i} className={classes.bodyRow}>
                      <TableCell className={classes.bodyCell} align="center">
                        {v.ticketDate}
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="center">
                        {v.ticketNo}
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="center">
                        {v.location}
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="center">
                        {v.totalInfo.totalPrice}
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="center">
                        {v.checkoutTech.techName}
                      </TableCell>
                      <TableCell className={classes.bodyCell} align="center">
                        <IconButton onClick={() => viewDetail(v)}>
                          <ListAlt />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    查無資料
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={historyRecord.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="每頁"
          />
        </TableContainer>
        {clickTicketData ? (
          <TicketDetailModal
            modalOpen={openDialog}
            handleModalClose={handleClose}
            ticketData={clickTicketData}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={searchReset}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

HistoryModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  historyRecord: PropTypes.array,
};

export default HistoryModal;
