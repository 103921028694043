const StatisticsPrintStyle = (theme) => ({
  formControl: {
    width: '100%',
  },
  fullScreenModal: {
    marginTop: '75px',
    zIndex: '9999',
  },
  modalTitle: {
    '& h2': {
      fontSize: 32,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
  },
  printPage: {
    margin: '5px 0px',
  },
  sheetTitle: {
    textAlign: 'left',
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#0606E6',
  },
  sheetMiddle: {
    textAlign: 'left',
    fontWeight: 'bold',
    color: '#800080',
  },
  printTable: {},
  borderCell: {
    border: '1px solid black',
    fontWeight: 'bold',
    padding: '7px 2px',
    fontSize: '0.9rem',
  },
});

export default StatisticsPrintStyle;
