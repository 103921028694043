import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  Slide,
} from '@material-ui/core';
import SignaturePad from 'react-signature-canvas';
import styles from './ModalStyle';
import CarImage from '../../assets/others/car.png';
const useStyles = makeStyles(styles);

// import dayjs from 'dayjs';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CarMarkModal = ({ modalOpen, handleModalClose, ticketData, setTicketData }) => {
  const classes = useStyles();
  const carMarkCanvas = useRef({});
  const [imageURL, setImageURL] = useState(null);

  const initCarImage = () => {
    const img = new Image();
    img.src = CarImage;
    img.onload = () => {
      const canvas = carMarkCanvas.current.getCanvas();
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, 700, 700);
    };
  };

  const clear = () => {
    carMarkCanvas.current.clear();
    initCarImage();
  };
  const save = () => {
    const imgUrl = carMarkCanvas.current.getCanvas().toDataURL('image/png', 1);
    setImageURL(imgUrl);
    let newData = { ...ticketData };
    newData.carMark = imgUrl;
    setTicketData(newData);
  };

  useEffect(() => {
    if (carMarkCanvas && modalOpen) initCarImage();
  }, [CarImage, modalOpen]);

  return (
    <Dialog
      id="carMarkModal"
      className={classes.fullScreenModal}
      fullScreen={true}
      maxWidth="md"
      open={modalOpen}
      onClose={handleModalClose}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.modalTitle}>車體標記</DialogTitle>
      <DialogContent>
        <DialogContentText>
          畫記完後請按完成：
          <Button color="inherit" variant="contained" onClick={clear} className={classes.titleBtn}>
            清除
          </Button>
          <Button color="primary" variant="contained" onClick={save} className={classes.titleBtn}>
            完成
          </Button>
        </DialogContentText>
        <FormControl className={classes.carMarkPad} variant="standard">
          <SignaturePad
            ref={carMarkCanvas}
            canvasProps={{
              className: 'signatureCanvas',
            }}
            minWidth={2}
            maxWidth={4}
            penColor="red"
          />
        </FormControl>

        <DialogContentText
          style={{
            marginTop: '30px',
          }}
        >
          目前標記預覽：
        </DialogContentText>
        {imageURL ? (
          <img
            src={imageURL}
            alt="my signature"
            style={{
              display: 'block',
              margin: '10px 50px auto',
              border: '1px solid black',
              width: '350px',
              height: '350px',
            }}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleModalClose}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CarMarkModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  ticketData: PropTypes.object,
  setTicketData: PropTypes.func,
};

export default CarMarkModal;
