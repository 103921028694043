const ModalStyle = (theme) => ({
  formControl: {
    width: '100%',
  },
  fullScreenModal: {
    marginTop: '75px',
    zIndex: '9999',
  },
  bodyRow: {
    '&:hover': {
      backgroundColor: 'rgba(132, 182, 249, 0.4)',
    },
    cursor: 'pointer',
  },
  bodyCell: {
    fontSize: 16,
    padding: '4px 0px 0px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  signPad: {
    width: '704px',
    height: '354px',
    border: '2px solid black',
    backgroundColor: 'white',
  },
  carMarkPad: {
    width: '704px',
    height: '704px',
    border: '2px solid black',
    backgroundColor: 'white',
  },
  modalTitle: {
    '& h2': {
      fontSize: 32,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
  },
  titleBtn: {
    margin: '0 5px',
  },
  submitBtn: {
    backgroundColor: '#2e7d32',
    color: 'white',
  },
  statementGrid: {
    [theme.breakpoints.down('sm')]: {},
  },
  statementTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'left',
    marginTop: '24px',
    color: '#3f51b5',
    borderBottom: '1px solid #3f51b5',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      marginTop: '20px',
    },
  },
  statementName: {
    fontSize: '16px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  statementValue: {
    fontSize: '16px',
    textAlign: 'left',
    fontWeight: 'bold',
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      margin: '0 3px',
    },
  },
  statementItem: {
    marginTop: '16px',
    [theme.breakpoints.down('sm')]: {},
  },
  printPage: {
    margin: '2px 10px',
  },
  printHeaderLeft: {
    fontSize: '18px',
  },
  printHeaderRight: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '9px',
    textAlign: 'right',
  },
  printMainTitle: {
    textAlign: 'center',
    fontSize: '36px',
    fontWeight: 'bold',
  },
  printSubTitle: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  borderCell: {
    border: '1px solid black',
    padding: '5px',
  },
  printTable: {
    marginBottom: '20px',
  },
  carMark: {
    border: '1px solid black',
    width: '200px',
    height: '200px',
  },
  printFooter: {
    fontSize: '9px',
  },
});

export default ModalStyle;
