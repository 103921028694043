import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CircularProgress, FormGroup, FormControlLabel, Checkbox, Grid } from '@material-ui/core';
// import axios from 'axios';
// import { Api, AxiosConfig } from 'GlobalDefine';

// import { useDispatch } from 'react-redux';
// import { openErrorSnackbar } from 'redux/actions';

import InputTextField from '../../components/Ticket/InputTextField';
import styles from './TabStyle';

const useStyles = makeStyles(styles);

const TotalTab = (props) => {
  const classes = useStyles();
  const { ticketData, totalInfo, setTicketData, isInvoice } = props;
  const { tireInfo, repairInfo, pAndPInfo } = ticketData;

  const [subTotal, setSubTotal] = useState(0);
  const [checks, setChecks] = useState({
    reserve: false,
    warranty: false,
    discount: false,
  });

  const updateTotalInfo = useCallback(
    (v) => {
      let newData = { ...ticketData };
      newData.totalInfo = { ...totalInfo, ...v };
      const currentUpd = Object.entries(v)[0];
      if (currentUpd[1] && (currentUpd[0] == 'noPrint' || currentUpd[0] == 'extra')) {
        if (currentUpd[0] == 'noPrint' && totalInfo.extra) newData.totalInfo.extra = false;
        if (currentUpd[0] == 'extra' && totalInfo.noPrint) newData.totalInfo.noPrint = false;
      }
      setTicketData(newData);
    },
    [ticketData],
  );

  useEffect(() => {
    const tire = tireInfo.reduce((acc, current) => acc + Number(current.sell * current.amount), 0);
    const repair = repairInfo.reduce(
      (acc, current) => acc + Number(current.price * current.amount),
      0,
    );
    const pAndP = pAndPInfo.reduce(
      (acc, current) => acc + Number(current.price * current.amount),
      0,
    );
    // 加總各項目並扣掉折扣金額
    let total = tire + repair + pAndP;
    if (checks.reserve || checks.warranty) total = Math.ceil(total * 0.9); // 有勾選預約或保固再打九折
    setSubTotal(total);
  }, [ticketData]);

  const handleChange = (event) => {
    const setDisable = { reserve: false, warranty: false, discount: false };
    setChecks({ ...setDisable, [event.target.name]: event.target.checked });
    // 勾掉折扣時先歸零
    if (event.target.name === 'discount' && event.target.checked === false) {
      updateTotalInfo({ discountAmount: 0 });
    }
  };

  useEffect(() => {
    let discount = '';
    if (checks.reserve) discount = 'reserve';
    if (checks.warranty) discount = 'warranty';
    if (checks.discount) discount = 'discount';
    if (!checks.reserve && !checks.warranty && !checks.discount) discount = '';
    const discountAmount = totalInfo.discountAmount ? totalInfo.discountAmount : 0;
    const subPrice = subTotal - parseInt(discountAmount);
    // 不印工單時，不加上營業稅。報帳用印工單是熟客優惠，也不加營業稅。
    if (totalInfo.noPrint || totalInfo.extra || isInvoice == '不需要發票') {
      updateTotalInfo({ totalPrice: subTotal, subPrice, discount });
    } else {
      updateTotalInfo({
        totalPrice: subTotal,
        subPrice,
        discount,
        noPrint: false,
        extra: false,
      });
    }
  }, [subTotal, totalInfo.noPrint, totalInfo.extra, totalInfo.discountAmount, checks, isInvoice]);

  return (
    <>
      {ticketData && totalInfo ? (
        <div className={classes.content}>
          <div className={classes.main}>
            <div className={classes.title}>{'金額總計'}</div>
            <FormGroup className={classes.detailContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={checks.reserve}
                    checked={checks.reserve}
                    onChange={handleChange}
                    name="reserve"
                    color="primary"
                  />
                }
                label="預約"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={checks.warranty}
                    checked={checks.warranty}
                    onChange={handleChange}
                    name="warranty"
                    color="primary"
                  />
                }
                label="保固"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={checks.discount}
                    checked={checks.discount}
                    onChange={handleChange}
                    name="discount"
                    color="primary"
                  />
                }
                label="折扣"
              />
            </FormGroup>
            <div className={classes.detailContainer}>
              {tireInfo
                .filter((el) => el.sell)
                .map((v, i) => {
                  return (
                    <Grid container spacing={1} key={i}>
                      <Grid item md={4} xs={2}>
                        <div className={classes.detailTitle}>{i == 0 ? '輪胎更換項目：' : ''}</div>
                      </Grid>
                      <Grid item md={8} xs={10}>
                        <div className={classes.detailContent}>
                          {`${i + 1}. ${v.tireType} / ${
                            v.tireType == '中古胎' ? v.number : v.brand
                          } / ${v.fullSize} / $ ${v.sell}`}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
            </div>
            <div className={classes.detailContainer}>
              {repairInfo.map((v) => {
                if (v.name && v.price) {
                  return (
                    <Grid container spacing={1} key={v.index}>
                      <Grid item md={4} xs={2}>
                        <div className={classes.detailTitle}>
                          {v.index == 0 ? '維修保養項目：' : ''}
                        </div>
                      </Grid>
                      <Grid item md={8} xs={10}>
                        <div className={classes.detailContent}>
                          {`${v.index + 1}. ${v.name} / ${v.amount} / $ ${v.price}`}
                        </div>
                      </Grid>
                    </Grid>
                  );
                }
              })}
            </div>
            <div className={classes.detailContainer}>
              {pAndPInfo.map((v) => {
                if (v.type && v.price) {
                  return (
                    <Grid container spacing={1} key={v.index}>
                      <Grid item md={4} xs={2}>
                        <div className={classes.detailTitle}>
                          {v.index == 0 ? '定位工資：' : ''}
                        </div>
                      </Grid>
                      <Grid item md={8} xs={10}>
                        <div className={classes.detailContent}>
                          {`${v.index + 1}. ${v.type} / ${v.content} / $ ${v.price}`}
                        </div>
                      </Grid>
                    </Grid>
                  );
                }
              })}
            </div>
            <div className={classes.detailContainer} style={{ marginTop: '20px' }}>
              <div className={classes.fieldTitle}>{'小計：'}</div>
              <div className={classes.fieldText}>{subTotal}</div>
            </div>
            {checks.discount ? (
              <div className={classes.detailContainer}>
                <div className={classes.fieldTitle}>{'折扣金額：'}</div>
                <InputTextField
                  val={totalInfo.discountAmount}
                  valChange={(discountAmount) => updateTotalInfo({ discountAmount })}
                  label="折扣金額"
                />
              </div>
            ) : null}
            <div className={classes.detailContainer}>
              <div className={classes.fieldTitle}>{'金額加總：'}</div>
              <InputTextField val={totalInfo.totalPrice} label="金額加總" disabled />
              <div className={classes.fieldText}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={totalInfo.noPrint}
                      checked={totalInfo.noPrint}
                      onChange={(e) => updateTotalInfo({ noPrint: e.target.checked })}
                      name="noPrint"
                      color="primary"
                    />
                  }
                  label="不印工單"
                  disabled={isInvoice == '需要發票' ? true : false}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={totalInfo.extra}
                      checked={totalInfo.extra}
                      onChange={(e) => updateTotalInfo({ extra: e.target.checked })}
                      name="extra"
                      color="primary"
                    />
                  }
                  label="報帳用印工單"
                  disabled={isInvoice == '需要發票' ? true : false}
                />
              </div>
            </div>
            <div className={classes.detailContainer}>
              <div className={classes.fieldTitle}>{'預付訂金：'}</div>
              <InputTextField
                val={totalInfo.prepayAmount}
                valChange={(prepayAmount) => updateTotalInfo({ prepayAmount })}
                label="訂金"
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative', marginLeft: '50%', left: '-20px' }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

TotalTab.propTypes = {
  ticketData: PropTypes.object,
  totalInfo: PropTypes.object,
  setTicketData: PropTypes.func,
  isInvoice: PropTypes.string,
};

export default TotalTab;
