import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import styles from './ModalStyle';
import { ApiUrl } from 'GlobalDefine';
import { useReactToPrint } from 'react-to-print';
import { useDispatch } from 'react-redux';
import { openErrorSnackbar } from 'redux/actions';
const useStyles = makeStyles(styles);

// import axios from 'axios';
// import { Api, AxiosConfig } from 'GlobalDefine';
// import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TicketPrintModal = ({ modalOpen, handleModalClose, ticketData }) => {
  const classes = useStyles();
  const {
    basicInfo,
    clientInfo,
    tireInfo,
    repairInfo,
    pAndPInfo,
    paymentInfo,
    totalInfo,
    suggestInfo,
  } = ticketData;
  const componentRef = useRef();
  const dispatch = useDispatch();
  const printFuc = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrint = () => {
    if (basicInfo.ticketNo) {
      printFuc();
    } else {
      dispatch(openErrorSnackbar('錯誤：資料讀取失敗！'));
    }
  };

  const getPaymentAmount = () => paymentInfo.length + 1;

  const transferLabel = (label) => {
    switch (label) {
      case 'cash':
        return '現金';
      case 'credit_card':
        return '信用卡';
      case 'remit':
        return '轉帳';
      case 'monthly':
        return '月結';
      case 'gift_voucher':
        return '禮券';
      case 'gov_voucher':
        return '消費券';
      default:
        break;
    }
  };
  const transLocationAddr = (location) => {
    switch (location) {
      case '總廠':
        return '無';
      case '板橋':
        return '新北市板橋區民生路三段170號';
      case '新店':
        return '新北市新店區溪園路87號';
      case '泰山':
        return '新北市泰山區貴子路7號';
      case '梧棲':
        return '台中市梧棲區中華路一段1032號之一';
      case '松山':
        return '台北市市民大道七段13號之1';
      case '桃園':
        return '桃園市蘆竹區中正北路475號';
      case '土城':
        return '新北市土城區金城路三段270號之1';
      case '北屯':
        return '台中市北屯區環中路一段19-5號';
      case '南屯':
        return '無';
      case '林口':
        return '新北市林口區仁愛路二段383之8號';
      default:
        return '無';
    }
  };
  const transLocationTel = (location) => {
    switch (location) {
      case '總廠':
        return '無';
      case '板橋':
        return '02-82524009';
      case '新店':
        return '02-22188318';
      case '泰山':
        return '02-2904-8866';
      case '梧棲':
        return '04-26632233';
      case '松山':
        return '02-27831996';
      case '桃園':
        return '03-311-0580';
      case '土城':
        return '02-2270-0712';
      case '北屯':
        return '04-2422-0233';
      case '南屯':
        return '無';
      default:
        return '無';
    }
  };

  return (
    <Dialog
      id="clientSignModal"
      className={classes.fullScreenModal}
      fullScreen={true}
      maxWidth="md"
      open={modalOpen}
      onClose={handleModalClose}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.modalTitle}>預覽列印</DialogTitle>
      <DialogContent ref={componentRef} className={classes.printPage}>
        <Grid container spacing={2} className={classes.printHeader}>
          <Grid item md={3} xs={3} className={classes.printHeaderLeft}>
            輪胎大師
          </Grid>
          <Grid item md={4} xs={4}></Grid>
          <Grid item md={5} xs={5} className={classes.printHeaderRight}>
            <span>輪胎大師-{basicInfo.location == '總廠' ? '總' : basicInfo.location}廠</span>
            <span>地址：{transLocationAddr(basicInfo.location)}</span>
            <span>電話：{transLocationTel(basicInfo.location)}</span>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.printMainTitle}>
          <Grid item md={12} xs={12}>
            結帳工單
          </Grid>
        </Grid>
        <Table className={classes.printTable}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.borderCell} align="left">
                單號：{basicInfo.ticketNo}
              </TableCell>
              <TableCell className={classes.borderCell} align="left">
                日期：{basicInfo.date}&ensp; &ensp;{basicInfo.shifts}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.borderCell} align="left">
                車牌：{clientInfo.licenseNo}
              </TableCell>
              <TableCell className={classes.borderCell} align="left">
                廠牌：{clientInfo.carBrand}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.borderCell} align="left">
                車主：{clientInfo.clientName}
              </TableCell>
              <TableCell className={classes.borderCell} align="left">
                型號：{clientInfo.carType}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.borderCell} align="left">
                電話：{clientInfo.clientPhone}
              </TableCell>
              <TableCell className={classes.borderCell} align="left">
                公里：{clientInfo.mileage}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container spacing={2} className={classes.printSubTitle}>
          <Grid item md={12} xs={12}>
            維修項目
          </Grid>
        </Grid>
        <Table className={classes.printTable}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.borderCell} align="center" style={{ height: 30 }}>
                項次
              </TableCell>
              <TableCell className={classes.borderCell} align="center">
                項目名
              </TableCell>
              <TableCell className={classes.borderCell} align="center">
                數量
              </TableCell>
              <TableCell className={classes.borderCell} align="center">
                售價
              </TableCell>
              <TableCell
                className={classes.borderCell}
                align="center"
                rowSpan={tireInfo.length + repairInfo.length + pAndPInfo.length}
                style={{ width: 220 }}
              >
                <img
                  className={classes.carMark}
                  src={`${ApiUrl}images/carMark/${basicInfo.ticketNo}_car.png`}
                  alt="signature"
                />
              </TableCell>
            </TableRow>
            {tireInfo
              .filter((ele) => ele.sell)
              .map((v, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell className={classes.borderCell} align="center">
                      {i + 1}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.tireType + ' - ' + v.fullSize}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.amount}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.sell}
                    </TableCell>
                  </TableRow>
                );
              })}
            {repairInfo
              .filter((ele) => ele.name !== '')
              .map((v, i) => {
                const items = tireInfo.filter((el) => el.brand !== '').length;
                return (
                  <TableRow key={i}>
                    <TableCell className={classes.borderCell} align="center">
                      {v.index + items + 1}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.name}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.amount}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.price}
                    </TableCell>
                  </TableRow>
                );
              })}
            {pAndPInfo
              .filter((ele) => ele.type !== '')
              .map((v, i) => {
                const items = tireInfo.filter((el) => el.brand !== '').length;
                const items2 = repairInfo.filter((el) => el.name !== '').length;
                return (
                  <TableRow key={i}>
                    <TableCell className={classes.borderCell} align="center">
                      {v.index + items + items2 + 1}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.type}-{v.content}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.amount ? v.amount : 1}
                    </TableCell>
                    <TableCell className={classes.borderCell} align="center">
                      {v.price}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <Table className={classes.printTable}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.borderCell} align="center">
                維修
              </TableCell>
              <TableCell className={classes.borderCell} align="center">
                {totalInfo.subPrice}
              </TableCell>
              <TableCell className={classes.borderCell} align="center" rowSpan={2}>
                付款方式
              </TableCell>
              <TableCell className={classes.borderCell} align="center" rowSpan={2}>
                金額
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.borderCell} align="center">
                營業稅5%
              </TableCell>
              <TableCell className={classes.borderCell} align="center">
                {totalInfo.noPrint || totalInfo.extra || totalInfo.isInvoice == '不需要發票'
                  ? 0
                  : Math.ceil(totalInfo.subPrice * 0.05)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.borderCell} align="center" rowSpan={getPaymentAmount()}>
                總金額
              </TableCell>
              <TableCell className={classes.borderCell} align="center" rowSpan={getPaymentAmount()}>
                {totalInfo.totalPrice}
              </TableCell>
            </TableRow>
            {paymentInfo.map((v, i) => {
              return (
                <TableRow key={i}>
                  <TableCell className={classes.borderCell} align="center">
                    {transferLabel(v.method)}
                  </TableCell>
                  <TableCell className={classes.borderCell} align="center">
                    {v.money}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell className={classes.borderCell} align="center" colSpan={2}>
                統一編號
              </TableCell>
              <TableCell className={classes.borderCell} align="center" colSpan={2}>
                {totalInfo.taxID}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table className={classes.printTable}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.borderCell} align="center">
                結帳技師
              </TableCell>
              <TableCell className={classes.borderCell} align="center">
                維修技師
              </TableCell>
              <TableCell className={classes.borderCell} align="center">
                客戶簽名
              </TableCell>
              <TableCell className={classes.borderCell} align="center" rowSpan={2}>
                本聯為客戶收執聯
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.borderCell} align="center">
                {basicInfo.checkoutTechName}
              </TableCell>
              <TableCell className={classes.borderCell} align="center">
                {basicInfo.repairTech.map((v) => {
                  return (
                    <div key={v.techID}>
                      <span>{v.techName}</span>
                      <br />
                    </div>
                  );
                })}
              </TableCell>
              <TableCell className={classes.borderCell} align="center" style={{ width: 320 }}>
                {basicInfo.ticketNo ? (
                  <img
                    src={`${ApiUrl}images/ticketSign/${basicInfo.ticketNo}_sign.png`}
                    alt="signImage"
                    style={{
                      margin: '10px',
                      width: '100px',
                      height: '50px',
                    }}
                  />
                ) : (
                  '客戶未簽名'
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table className={classes.printTable}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.borderCell} align="center">
                建議事項
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.borderCell} align="center">
                {suggestInfo ? suggestInfo : '無'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container spacing={2} className={classes.printFooter}>
          <Grid item md={12} xs={12}>
            <span>委修人同意事項：</span>
            <br />
            <span>1. 本公司不負責天災人禍及其他不可抗力或不可歸咎本公司之災害而發生之損害賠償</span>
            <br />
            <span>2. 車內貴重物品及現金請貴車主自行帶走，本公司不負保管責任</span>
            <br />
            <span>3. 貴車主同意本偉修單支項目價格，並允許維修人員於街道或其他地點進行試車</span>
            <br />
            <span>4. 本公司提供零件更換皆享有保固（消耗品及電子零件除外）</span>
            <br />
            <span>
              5.
              車輛委託完畢請按照約定時間取車，並以現金或信用卡結帳，若超過取車時間一個月仍未清償保修內用獲取車者，視同拋棄所有權，本公司得就偉修車輛自行處理且優先償付一切費用
            </span>
            <br />
            <span>6. 本公司更換中古胎享有7天保固</span>
            <br />
            <span>7. 在維修過程中如因車齡等自然損耗不再本公司責任範圍內</span>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleModalClose}>
          取消
        </Button>
        <Button color="primary" variant="contained" onClick={handlePrint}>
          列印
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TicketPrintModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  ticketData: PropTypes.object,
};

export default TicketPrintModal;
