import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import axios from 'axios';
import { Api, AxiosConfig } from 'GlobalDefine';
import { useDispatch } from 'react-redux';
import { openErrorSnackbar } from 'redux/actions';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
}));

function UsersField({ val, valChange, multiple, isApp }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const icon = <Done fontSize="medium" color="inherit" />;
  const checkedIcon = <Done fontSize="medium" color="primary" />;

  const getUsers = async () => {
    const api = isApp ? Api.Ticket.GetAllAppTechs : Api.Ticket.GetAllTechs;
    try {
      const result = await axios.get(api, AxiosConfig.General);
      if (result.status === 200) {
        if (result.data !== 'Not Found.') {
          const filterOptions = result.data.map((v) => {
            return { label: `${v.email}-${v.name}`, techID: v.email, techName: v.name };
          });
          setOptions(filterOptions);
        }
      } else {
        dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      }
    } catch (error) {
      dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
      console.log(error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getUsers();
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Autocomplete
      className={classes.formControl}
      multiple={multiple}
      disableCloseOnSelect
      id="combo-box-demo"
      value={val}
      options={options}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {option.label}
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginLeft: 'auto', padding: '8px 10px' }}
            checked={selected}
          />
        </li>
      )}
      renderInput={(params) => <TextField {...params} label="請選擇" />}
      isOptionEqualToValue={(option, value) => option.techID === value.techID}
      onChange={(e, v) => valChange(v)}
    />
  );
}

UsersField.propTypes = {
  val: PropTypes.array,
  valChange: PropTypes.func,
  multiple: PropTypes.bool,
  isApp: PropTypes.bool,
};

export default UsersField;
