import { React, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Backdrop, Card, Toolbar, Button, Container } from '@material-ui/core';
import { BorderColor, Save } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { openErrorSnackbar } from 'redux/actions';

import BasicTab from './BasicTab';
import ClientTab from './ClientTab';
import OldTireTab from './OldTireTab';
import NewTireTab from './NewTireTab';
import RepairTab from './RepairTab';
import PositionAndPayTab from './PositionAndPayTab';
import SuggestTab from './SuggestTab';
import AppTab from './AppTab';
import TotalTab from './TotalTab';
import InvoiceTab from './InvoiceTab';
import PaymentTab from './PaymentTab';
import ClientSignModal from '../../components/Ticket/ClientSignModal';
import PreviewModal from '../../components/Ticket/PreviewModal';
import CarMarkModal from '../../components/Ticket/CarMarkModal';
import styles from './MainStyle';

const useStyles = makeStyles(styles);

const PrepayTicket = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.user);
  const [signModalOpen, setSignModalOpen] = useState(false);
  const [carMarkModalOpen, setCarMarkModalOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [ticketDone, setTicketDone] = useState(false);
  const handleSignModalOpen = () => setSignModalOpen(true);
  const handleSignModalClose = () => setSignModalOpen(false);
  const handlePreviewModalOpen = () => setPreviewModalOpen(true);
  const handlePreviewModalClose = () => setPreviewModalOpen(false);
  const handleCarMarkModalOpen = () => setCarMarkModalOpen(true);
  const handleCarMarkModalClose = () => setCarMarkModalOpen(false);

  const autoGetDate = () => {
    const now = dayjs().format('HH:mm:ss');
    let date = '';
    if (now > '09:00' && now < '23:00') {
      date = dayjs().format('YYYY-MM-DD');
    } else {
      date = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    }
    return date;
  };
  const autoGetShift = () => {
    const now = dayjs().format('HH:mm:ss');
    let shift = '';
    if (now > '09:00' && now < '23:00') {
      shift = '早班';
    } else {
      shift = '晚班';
    }
    return shift;
  };

  const [ticketData, setTicketData] = useState({
    ticketType: 'Prepay',
    signImage: '',
    carMark: '',
    basicInfo: {
      ticketNo: '',
      date: autoGetDate(),
      checkoutTechID: userStatus.info.account,
      checkoutTechName: userStatus.info.name,
      repairTech: [],
      location: '',
      shifts: autoGetShift(),
      isEmployee: false,
    },
    clientInfo: {
      isExist: false,
      licenseNo: '',
      clientName: '',
      clientPhone: '',
      carBrand: '',
      carType: '',
      mileage: '',
    },
    tireInfo: [
      {
        index: 0,
        tireType: '中古胎',
        number: '',
        fullSize: '',
        price: '',
        sell: '',
        memo: '',
        brand: '',
        firm: '',
        amount: 1,
        warn: '',
      },
      {
        index: 1,
        tireType: '中古胎',
        number: '',
        fullSize: '',
        price: '',
        sell: '',
        memo: '',
        brand: '',
        firm: '',
        amount: 1,
        warn: '',
      },
      {
        index: 2,
        tireType: '中古胎',
        number: '',
        fullSize: '',
        price: '',
        sell: '',
        memo: '',
        brand: '',
        firm: '',
        amount: 1,
        warn: '',
      },
      {
        index: 3,
        tireType: '中古胎',
        number: '',
        fullSize: '',
        price: '',
        sell: '',
        memo: '',
        brand: '',
        firm: '',
        amount: 1,
        warn: '',
      },
      {
        index: 4,
        tireType: '新胎',
        number: '',
        fullSize: '',
        price: '',
        sell: '',
        memo: '',
        brand: '',
        firm: '',
        amount: 0,
        warn: '',
      },
      {
        index: 5,
        tireType: '新胎',
        number: '',
        fullSize: '',
        price: '',
        sell: '',
        memo: '',
        brand: '',
        firm: '',
        amount: 0,
        warn: '',
      },
      {
        index: 6,
        tireType: '新胎',
        number: '',
        fullSize: '',
        price: '',
        sell: '',
        memo: '',
        brand: '',
        firm: '',
        amount: 0,
        warn: '',
      },
      {
        index: 7,
        tireType: '新胎',
        number: '',
        fullSize: '',
        price: '',
        sell: '',
        memo: '',
        brand: '',
        firm: '',
        amount: 0,
        warn: '',
      },
    ],
    repairInfo: [
      { index: 0, name: '', amount: '', price: '', memo: '' },
      { index: 1, name: '', amount: '', price: '', memo: '' },
      { index: 2, name: '', amount: '', price: '', memo: '' },
      { index: 3, name: '', amount: '', price: '', memo: '' },
      { index: 4, name: '', amount: '', price: '', memo: '' },
    ],
    pAndPInfo: [
      { index: 0, type: '', content: '', price: '', amount: 0, memo: '' },
      { index: 1, type: '', content: '', price: '', amount: 0, memo: '' },
    ],
    suggestInfo: '',
    appInfo: {
      appTech: '',
      memo: '',
    },
    totalInfo: {
      isInvoice: '',
      totalPrice: 0,
      subPrice: 0,
      taxID: '',
      company: '',
      receiver: '',
      address: '',
      noPrint: false,
      extra: false,
      selfGet: false,
      discount: '',
      discountAmount: 0,
      prepayAmount: 0,
    },
    paymentInfo: [],
  });
  const checkAllPriceIsDone = () => {
    const tirePriceEmpty = ticketData.tireInfo.filter((v) => v.brand && v.sell == '');
    const repairPriceEmpty = ticketData.repairInfo.filter((v) => v.name && v.price == '');
    if (tirePriceEmpty.length || repairPriceEmpty.length) {
      return false;
    } else {
      return true;
    }
  };
  const clearEmptyPriceItems = () => {
    let newData = { ...ticketData };
    newData.pAndPInfo.forEach((el) => {
      if (!el.price || !el.amount) {
        el.type = '';
        el.content = '';
      }
    });
    setTicketData(newData);
  };
  const checkTotalIsDone = (click) => {
    let payTotal = 0;
    const checkPrice = checkAllPriceIsDone();
    if (!checkPrice) return dispatch(openErrorSnackbar('錯誤：項目售價或價格為填寫完整！'));
    ticketData.paymentInfo.forEach((v) => (payTotal += parseInt(v.money)));
    const correctTotal = ticketData.totalInfo.prepayAmount;
    if (!ticketData.carMark) return dispatch(openErrorSnackbar('錯誤：車體標記未完成！'));
    if (correctTotal == payTotal) {
      clearEmptyPriceItems();
      if (click == 'sign') handleSignModalOpen();
      if (click == 'preview') handlePreviewModalOpen();
    } else {
      return dispatch(openErrorSnackbar('錯誤：付款金額與實際總額不相符！'));
    }
  };

  useEffect(() => {}, []);

  if (isEmpty(userStatus)) {
    return null;
  }
  return (
    <div className={classes.mainContent}>
      <div className={ticketDone ? 'blockRoot' : 'mainRoot'}>
        <Grid item xs={12} md={7} style={{ padding: 0, margin: '12px' }}>
          <Paper className={classes.paper}>
            <div className={classes.title}>{'訂金工單'}</div>
            <Card className={classes.card}>
              <BasicTab
                ticketData={ticketData}
                basicInfo={ticketData.basicInfo}
                setTicketData={setTicketData}
              />
            </Card>
            <Card className={classes.card}>
              <ClientTab
                ticketData={ticketData}
                clientInfo={ticketData.clientInfo}
                setTicketData={setTicketData}
              />
            </Card>
            <Card className={classes.card}>
              <OldTireTab
                ticketData={ticketData}
                tireInfo={ticketData.tireInfo}
                setTicketData={setTicketData}
              />
            </Card>
            <Card className={classes.card}>
              <NewTireTab
                ticketData={ticketData}
                tireInfo={ticketData.tireInfo}
                setTicketData={setTicketData}
              />
            </Card>
            <Card className={classes.card}>
              <RepairTab
                ticketData={ticketData}
                setTicketData={setTicketData}
                repairInfo={ticketData.repairInfo}
              />
            </Card>
            <Card className={classes.card}>
              <PositionAndPayTab
                ticketData={ticketData}
                setTicketData={setTicketData}
                pAndPInfo={ticketData.pAndPInfo}
              />
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: 0, margin: '12px' }}>
          <Paper className={classes.paper}>
            <SuggestTab
              ticketData={ticketData}
              setTicketData={setTicketData}
              suggestInfo={ticketData.suggestInfo}
            />
          </Paper>
          <Paper className={classes.paper}>
            <AppTab
              ticketData={ticketData}
              setTicketData={setTicketData}
              appInfo={ticketData.appInfo}
            />
          </Paper>
          <Paper className={classes.paper}>
            <TotalTab
              ticketData={ticketData}
              setTicketData={setTicketData}
              totalInfo={ticketData.totalInfo}
              isInvoice={ticketData.totalInfo.isInvoice}
            />
          </Paper>
          <Paper className={classes.paper}>
            <InvoiceTab
              ticketData={ticketData}
              setTicketData={setTicketData}
              totalInfo={ticketData.totalInfo}
              isInvoice={ticketData.totalInfo.isInvoice}
            />
          </Paper>
          <Paper className={classes.paper}>
            <PaymentTab
              ticketData={ticketData}
              setTicketData={setTicketData}
              paymentInfo={ticketData.paymentInfo}
            />
          </Paper>
        </Grid>
        {userStatus.info.authority === 3 && (
          <Backdrop className={classes.backdrop} open={true}>
            <div className={classes.backdropText}>{'權限不足'}</div>
          </Backdrop>
        )}
      </div>

      <div className={classes.secondaryContainer}>
        <CarMarkModal
          modalOpen={carMarkModalOpen}
          handleModalClose={handleCarMarkModalClose}
          ticketData={ticketData}
          setTicketData={setTicketData}
        />
        <ClientSignModal
          modalOpen={signModalOpen}
          handleModalClose={handleSignModalClose}
          ticketData={ticketData}
          setTicketData={setTicketData}
          setTicketDone={setTicketDone}
        />
        <PreviewModal
          modalOpen={previewModalOpen}
          handleModalClose={handlePreviewModalClose}
          ticketData={ticketData}
          ticketDone={ticketDone}
        />
        <Container maxWidth="lg" className={classes.noPadding}>
          <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
            <Button
              className={classes.toolbarLink}
              startIcon={<BorderColor />}
              onClick={handleCarMarkModalOpen}
              disabled={ticketDone}
            >
              車體標記
            </Button>
            <Button
              className={classes.toolbarLink}
              startIcon={<BorderColor />}
              onClick={() => checkTotalIsDone('sign')}
              disabled={ticketDone}
            >
              客戶簽名
            </Button>
            <Button
              className={classes.toolbarLink}
              startIcon={<Save />}
              onClick={() => checkTotalIsDone('preview')}
            >
              存檔並列印
            </Button>
          </Toolbar>
        </Container>
      </div>
    </div>
  );
};

export default PrepayTicket;
