import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CircularProgress, Button } from '@material-ui/core';
import { History } from '@material-ui/icons';
import axios from 'axios';
import { Api, AxiosConfig } from 'GlobalDefine';
import dayjs from 'dayjs';
// import Swal from 'sweetalert2';

import { useDispatch } from 'react-redux';
import { openSuccessUpdateSnackbar, openErrorSnackbar } from 'redux/actions';
import InputTextField from '../../components/Ticket/InputTextField';
import HistoryModal from '../../components/Ticket/HistoryModal';
import styles from './TabStyle';

const useStyles = makeStyles(styles);

const ClientTab = (props) => {
  const classes = useStyles();
  const { ticketData, clientInfo, setTicketData } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [historyRecord, setHistoryRecord] = useState([]);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const dispatch = useDispatch();

  const updateClientInfo = useCallback(
    (v) => {
      let newData = { ...ticketData };
      newData.clientInfo = { ...clientInfo, ...v };
      setTicketData(newData);
    },
    [ticketData],
  );

  const getClientHistory = async () => {
    const licenseNo = clientInfo.licenseNo;
    if (licenseNo) {
      try {
        const res = await axios.post(
          `${Api.Ticket.SearchTickets}`,
          { licenseNo },
          AxiosConfig.General,
        );
        const newData = res.data.map((v) => {
          v.ticketDate = dayjs(v.date).format('YYYY-MM-DD');
          v.repairTech = JSON.parse(v.repairTech);
          v.checkoutTech = JSON.parse(v.checkoutTech);
          v.clientInfo = JSON.parse(v.clientInfo);
          v.tireInfo = JSON.parse(v.tireInfo);
          v.repairInfo = JSON.parse(v.repairInfo);
          v.pAndPInfo = JSON.parse(v.pAndPInfo);
          v.suggestInfo = JSON.parse(v.suggestInfo);
          v.appInfo = JSON.parse(v.appInfo);
          v.totalInfo = JSON.parse(v.totalInfo);
          v.paymentInfo = JSON.parse(v.paymentInfo);
          v.basicInfo = {
            ticketNo: v.ticketNo,
            date: dayjs(v.date).format('YYYY-MM-DD'),
            checkoutTechID: v.checkoutTech.techID,
            checkoutTechName: v.checkoutTech.techName,
            repairTech: [...v.repairTech],
            location: v.location,
            shifts: v.shift,
          };
          return v;
        });
        setHistoryRecord(newData);
        handleModalOpen();
      } catch (error) {
        console.log(error);
      }
    } else {
      dispatch(openErrorSnackbar('請先填入客戶車牌！'));
    }
  };

  const getClientInfo = async () => {
    const licenseNo = clientInfo.licenseNo;
    const res = await axios.get(
      `${Api.Client.GetClientByLicenseNo}/${licenseNo}`,
      AxiosConfig.General,
    );
    if (res.data === 'Not Found.') {
      dispatch(openSuccessUpdateSnackbar('無此客戶！'));
      updateClientInfo({
        isExist: false,
        clientName: '',
        clientPhone: '',
        carBrand: '',
        carType: '',
        mileage: '',
      });
    }
    if (res.data && res.data !== 'Not Found.') {
      const selectData = res.data;
      selectData.isExist = true;
      delete selectData.id;
      delete selectData.createAt;
      delete selectData.updateAt;
      updateClientInfo({ ...selectData });
    }
    setIsSearch(false);
  };

  useEffect(() => {
    if (clientInfo.licenseNo) {
      setIsSearch(true);
      const timer = setTimeout(() => {
        getClientInfo();
      }, 700);

      return () => clearTimeout(timer);
    }
  }, [clientInfo.licenseNo]);

  return (
    <>
      {ticketData && clientInfo ? (
        <div className={classes.content}>
          <div className={classes.main}>
            <div className={classes.title}>{'客戶資訊'}</div>
            <HistoryModal
              modalOpen={modalOpen}
              handleModalClose={handleModalClose}
              historyRecord={historyRecord}
            />
            <div className={classes.selectBtnGroup}>
              <Button
                className={classes.selectBtn}
                variant="contained"
                color="primary"
                startIcon={<History />}
                onClick={getClientHistory}
              >
                {'歷史紀錄'}
              </Button>
            </div>
            <div style={isSearch ? { display: 'flex' } : { display: 'none' }}>
              <CircularProgress />
            </div>
            <div className={classes.columnGroup}>
              <div className={classes.clientColumn}>
                <div className={classes.fieldTitle}>{'車牌：'}</div>
                <InputTextField
                  val={clientInfo.licenseNo}
                  valChange={(licenseNo) =>
                    updateClientInfo({ licenseNo: licenseNo.toUpperCase() })
                  }
                  label="車牌"
                />
              </div>
              <div className={classes.clientColumn}>
                <div className={classes.fieldTitle}>{'客戶姓名：'}</div>
                <InputTextField
                  val={clientInfo.clientName}
                  valChange={(clientName) => updateClientInfo({ clientName })}
                  label="客戶姓名"
                />
              </div>
              <div className={classes.clientColumn}>
                <div className={classes.fieldTitle}>{'客戶電話：'}</div>
                <InputTextField
                  val={clientInfo.clientPhone}
                  valChange={(clientPhone) => updateClientInfo({ clientPhone })}
                  label="客戶電話"
                />
              </div>
              <div className={classes.clientColumn}>
                <div className={classes.fieldTitle}>{'廠牌：'}</div>
                <InputTextField
                  val={clientInfo.carBrand}
                  valChange={(carBrand) => updateClientInfo({ carBrand })}
                  label="廠牌"
                />
              </div>
              <div className={classes.clientColumn}>
                <div className={classes.fieldTitle}>{'型號：'}</div>
                <InputTextField
                  val={clientInfo.carType}
                  valChange={(carType) => updateClientInfo({ carType })}
                  label="型號"
                />
              </div>
              <div className={classes.clientColumn}>
                <div className={classes.fieldTitle}>{'里程：'}</div>
                <InputTextField
                  val={clientInfo.mileage}
                  valChange={(mileage) => updateClientInfo({ mileage })}
                  label="里程"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative', marginLeft: '50%', left: '-20px' }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

ClientTab.propTypes = {
  ticketData: PropTypes.object,
  clientInfo: PropTypes.object,
  setTicketData: PropTypes.func,
};

export default ClientTab;

// const checkClientInfoDone = () => {
//   const infoValues = Object.values(clientInfo);
//   const findEmpty = infoValues.find((v) => v == '');
//   return findEmpty === '' ? false : true;
// };

// const handleSaveClient = async () => {
//   const isClientInfoDone = checkClientInfoDone();
//   if (isClientInfoDone) {
//     const submit = { ...clientInfo };
//     try {
//       const checkResult = await axios.post(
//         `${Api.Client.CheckClientExist}`,
//         submit,
//         AxiosConfig.General,
//       );
//       console.log(checkResult.data);
//       if (checkResult.status === 200) {
//         const client = checkResult.data;
//         if (client !== 'Not Found.') {
//           Swal.fire({
//             title: `相同車牌資料已存在！<br />是否覆蓋以下資訊？`,
//             html: `車牌：${client.licenseNo}<br />客戶姓名：${client.clientName}<br />客戶電話：${client.clientPhone}<br />廠牌：${client.carBrand}<br />型號：${client.carType}<br />里程：${client.mileage}`,
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#3085d6',
//             cancelButtonColor: '#d33',
//             confirmButtonText: '確定',
//             cancelButtonText: '取消',
//           }).then(async (r) => {
//             if (r.isConfirmed) {
//               const res = await axios.post(
//                 `${Api.Client.UpdateClientInfo}`,
//                 submit,
//                 AxiosConfig.General,
//               );
//               if (res.data === 'success') {
//                 Swal.fire({ icon: 'success', title: '儲存成功！', timer: 2500 });
//               }
//             }
//           });
//         } else {
//           const res = await axios.post(
//             `${Api.Client.CreateClientInfo}`,
//             submit,
//             AxiosConfig.General,
//           );
//           if (res.data === 'success') {
//             Swal.fire({ icon: 'success', title: '儲存成功！', timer: 2500 });
//           }
//         }
//       } else {
//         dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
//         console.log('Fetch Error:', checkResult);
//       }
//     } catch (error) {
//       console.log('Fetch Error:', error);
//       dispatch(openErrorSnackbar('錯誤：請聯繫維護人員！'));
//     }
//   } else {
//     dispatch(openErrorSnackbar('錯誤：請填寫完整資料再進行儲存！'));
//   }
// };
