import { TICKET_GET_DETAIL, TICKET_GENERATE_BY_PREPAY, TICKET_CLEAR } from '../actionTypes';

const ticketGetDetail = () => {
  return {
    type: TICKET_GET_DETAIL,
  };
};

const ticketGenerateByPrepay = (data) => {
  return {
    type: TICKET_GENERATE_BY_PREPAY,
    payload: data,
  };
};

const ticketClear = () => {
  return {
    type: TICKET_CLEAR,
  };
};

export { ticketGetDetail, ticketGenerateByPrepay, ticketClear };
