import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';

const PaymentTabLabels = ({ setLabelFunc }) => {
  const [state, setState] = useState({
    cash: false,
    credit_card: false,
    remit: false,
    monthly: false,
    gift_voucher: false,
    gov_voucher: false,
  });
  // const [isOver, setIsOver] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (!isEmpty(state)) {
      setLabelFunc(state);
    }
    // const count = Object.values(state).filter((v) => v === true).length;
    // count > 1 ? setIsOver(true) : setIsOver(false);
  }, [state]);

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            value={state.cash}
            checked={state.cash}
            onChange={handleChange}
            name="cash"
            color="primary"
          />
        }
        label="現金"
      />
      <FormControlLabel
        control={
          <Checkbox
            value={state.credit_card}
            checked={state.credit_card}
            onChange={handleChange}
            name="credit_card"
            color="primary"
          />
        }
        label="信用卡"
      />
      <FormControlLabel
        control={
          <Checkbox
            value={state.remit}
            checked={state.remit}
            onChange={handleChange}
            name="remit"
            color="primary"
          />
        }
        label="轉帳"
      />
      <FormControlLabel
        control={
          <Checkbox
            value={state.monthly}
            checked={state.monthly}
            onChange={handleChange}
            name="monthly"
            color="primary"
          />
        }
        label="月結"
      />
      <FormControlLabel
        control={
          <Checkbox
            value={state.gift_voucher}
            checked={state.gift_voucher}
            onChange={handleChange}
            name="gift_voucher"
            color="primary"
          />
        }
        label="禮券"
      />
      <FormControlLabel
        control={
          <Checkbox
            value={state.gov_voucher}
            checked={state.gov_voucher}
            onChange={handleChange}
            name="gov_voucher"
            color="primary"
          />
        }
        label="消費券"
      />
    </FormGroup>
  );
};

PaymentTabLabels.propTypes = {
  setLabelFunc: PropTypes.func,
};

export default PaymentTabLabels;
