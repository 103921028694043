import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import styles from './TabStyle';
import PositionAndPayField from '../../components/Dropdown/PositionAndPayField';

const useStyles = makeStyles(styles);

const PositionAndPayTab = (props) => {
  const classes = useStyles();
  const { ticketData, pAndPInfo, setTicketData } = props;

  const updatePAndPInfo = useCallback(
    (v, index) => {
      let newData = { ...ticketData };
      const currentItem = pAndPInfo[index];
      newData.pAndPInfo[index] = { ...currentItem, ...v };
      setTicketData(newData);
    },
    [ticketData],
  );

  useEffect(() => {
    // 初始化預設一個
  }, []);

  return (
    <>
      {ticketData && pAndPInfo ? (
        <div className={classes.content}>
          <div className={classes.main}>
            <div className={classes.title}>{'定位，工資'}</div>
            <div className={classes.column}></div>
            <div className={classes.cardColumn}>
              <TableContainer component={Paper} elevation={4} style={{ marginTop: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.headerCell} align="center">
                        類型
                      </TableCell>
                      <TableCell className={classes.headerCell} align="center">
                        項目
                      </TableCell>
                      <TableCell className={classes.headerCell} align="center">
                        數量
                      </TableCell>
                      <TableCell className={classes.headerCell} align="center">
                        價格
                      </TableCell>
                      <TableCell className={classes.headerCell} align="center">
                        備註
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pAndPInfo.map((v, i) => (
                      <TableRow key={i} className={classes.bodyRow}>
                        <TableCell className={classes.bodyCell} align="center">
                          <PositionAndPayField
                            val={v.type}
                            valChange={(type) => updatePAndPInfo({ type }, v.index)}
                          />
                        </TableCell>
                        <TableCell className={classes.bodyCell} align="center">
                          <TextField
                            className={classes.cellText}
                            variant="outlined"
                            size="small"
                            value={v.content}
                            onChange={(e) => updatePAndPInfo({ content: e.target.value }, v.index)}
                            multiline
                          />
                        </TableCell>
                        <TableCell className={classes.bodyCell} align="center">
                          <TextField
                            className={classes.cellText}
                            variant="outlined"
                            size="small"
                            value={v.amount}
                            onChange={(e) => updatePAndPInfo({ amount: e.target.value }, v.index)}
                          />
                        </TableCell>
                        <TableCell className={classes.bodyCell} align="center">
                          <TextField
                            className={classes.cellText}
                            variant="outlined"
                            size="small"
                            value={v.price}
                            onChange={(e) => updatePAndPInfo({ price: e.target.value }, v.index)}
                          />
                        </TableCell>
                        <TableCell className={classes.bodyCell} align="center">
                          <TextField
                            className={classes.cellText}
                            variant="outlined"
                            size="small"
                            value={v.memo}
                            onChange={(e) => updatePAndPInfo({ memo: e.target.value }, v.index)}
                            multiline
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative', marginLeft: '50%', left: '-20px' }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

PositionAndPayTab.propTypes = {
  ticketData: PropTypes.object,
  setTicketData: PropTypes.func,
  pAndPInfo: PropTypes.array,
};

export default PositionAndPayTab;
