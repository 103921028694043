import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  openSuccessUpdateSnackbar,
  openErrorSnackbar,
  ticketGenerateByPrepay,
} from 'redux/actions';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
} from '@material-ui/core';
import styles from '../../components/Ticket/ModalStyle';
const useStyles = makeStyles(styles);
import Swal from 'sweetalert2';
import axios from 'axios';
import { Api, AxiosConfig } from 'GlobalDefine';
import { ApiUrl } from 'GlobalDefine';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TicketDetailModal = ({ modalOpen, handleModalClose, ticketData }) => {
  const { basicInfo, clientInfo, tireInfo, repairInfo, totalInfo, paymentInfo, pAndPInfo, status } =
    ticketData;
  const classes = useStyles();
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.user);
  const history = useHistory();

  const transferLabel = (label) => {
    switch (label) {
      case 'cash':
        return '現金';
      case 'credit_card':
        return '信用卡';
      case 'remit':
        return '轉帳';
      case 'monthly':
        return '月結';
      case 'gift_voucher':
        return '禮券';
      case 'gov_voucher':
        return '消費券';
      default:
        break;
    }
  };

  const handleAnnulTicket = async () => {
    handleModalClose();
    Swal.fire({
      title: `確定作廢此工單？`,
      text: '請留意！工單作廢後無法復原！',
      inputLabel: '作廢備註:',
      input: 'text',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '確定',
      cancelButtonText: '取消',
    }).then(async (r) => {
      if (r.isConfirmed) {
        try {
          // 作廢工單要更新庫存的輪胎為現貨&清除出貨資訊
          const tireNumbers = tireInfo.map((el) => el.number);
          for (const val of tireNumbers) {
            if (val && val !== '') {
              await axios.post(
                `${Api.Tires.UpdateTireStatus}`,
                { number: val, status: '現貨', client: '' },
                AxiosConfig.General,
              );
              await axios.post(
                `${Api.Tires.UpdateTireShippingDate}`,
                { number: val, shipping_date: '' },
                AxiosConfig.General,
              );
            }
          }
          const res1 = await axios.post(
            `${Api.Ticket.UpdateTicketStatus}`,
            { ticketNo: basicInfo.ticketNo, status: '作廢' },
            AxiosConfig.General,
          );
          const res2 = await axios.post(
            `${Api.Ticket.UpdateTicketMemo}`,
            { ticketNo: basicInfo.ticketNo, ticketMemo: r.value },
            AxiosConfig.General,
          );
          if (res1 && res2) {
            dispatch(openSuccessUpdateSnackbar('已成功作廢此工單'));
          }
        } catch (error) {
          console.log('Err:', error);
          dispatch(openErrorSnackbar('錯誤！請聯繫系統維護人員！'));
        }
      }
    });
  };

  const createByPrepay = () => {
    dispatch(ticketGenerateByPrepay(ticketData));
    history.push('/commonTicket/createByPrepay');
  };

  return (
    <Dialog
      id="ticketDetailModal"
      className={classes.fullScreenModal}
      fullScreen={true}
      maxWidth="md"
      open={modalOpen}
      onClose={handleModalClose}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.modalTitle}>工單明細</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={classes.statementGrid}>
          <Grid item md={12} xs={12}>
            <div className={classes.statementTitle}>基本資料</div>
          </Grid>
          <Grid item md={5} xs={5}>
            <span className={classes.statementName}>服務地點：</span>
            <span className={classes.statementValue}>{basicInfo.location}</span>
          </Grid>
          <Grid item md={6} xs={6}>
            <span className={classes.statementName}>工單單號：</span>
            <span className={classes.statementValue}>
              {basicInfo.ticketNo}
              {status ? `(${status})` : ''}
            </span>
          </Grid>
          <Grid item md={5} xs={5}>
            <span className={classes.statementName}>日期：</span>
            <span className={classes.statementValue}>{basicInfo.date}</span>
          </Grid>
          <Grid item md={6} xs={6}>
            <span className={classes.statementName}>結帳技師：</span>
            <span className={classes.statementValue}>
              {basicInfo.checkoutTechID}({basicInfo.checkoutTechName})
            </span>
          </Grid>
          <Grid item md={12} xs={12}>
            <span className={classes.statementName}>維修技師：</span>
            {basicInfo.repairTech.map((v, i) => {
              return (
                <span key={i} className={classes.statementValue}>
                  {v.techID}-{v.techName}
                </span>
              );
            })}
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.statementGrid}>
          <Grid item md={12} xs={12}>
            <div className={classes.statementTitle}>客戶資料</div>
          </Grid>
          <Grid item md={6} xs={6}>
            <span className={classes.statementName}>車牌：</span>
            <span className={classes.statementValue}>{clientInfo.licenseNo}</span>
          </Grid>
          <Grid item md={6} xs={6}>
            <span className={classes.statementName}>姓名：</span>
            <span className={classes.statementValue}>{clientInfo.clientName}</span>
          </Grid>
          <Grid item md={6} xs={6}>
            <span className={classes.statementName}>電話：</span>
            <span className={classes.statementValue}>{clientInfo.clientPhone}</span>
          </Grid>
          <Grid item md={6} xs={6}>
            <span className={classes.statementName}>廠牌：</span>
            <span className={classes.statementValue}>{clientInfo.carBrand}</span>
          </Grid>
          <Grid item md={6} xs={6}>
            <span className={classes.statementName}>型號：</span>
            <span className={classes.statementValue}>{clientInfo.carType}</span>
          </Grid>
          <Grid item md={6} xs={6}>
            <span className={classes.statementName}>里程：</span>
            <span className={classes.statementValue}>{clientInfo.mileage}</span>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <div className={classes.statementTitle}>輪胎更換項目</div>
        </Grid>
        {tireInfo.map((v, i) => {
          return (
            <Grid container spacing={1} key={i} className={classes.statementItem}>
              <Grid item md={2} xs={2}>
                <span className={classes.statementName}>{i + 1}.</span>
              </Grid>
              <Grid item md={5} xs={5}>
                <span className={classes.statementName}>類型：</span>
                <span className={classes.statementValue}>{v.tireType}</span>
              </Grid>
              <Grid item md={5} xs={5}>
                <span className={classes.statementName}>編號：</span>
                <span className={classes.statementValue}>{v.number}</span>
              </Grid>
              <Grid item md={2} xs={2}></Grid>
              <Grid item md={5} xs={5}>
                <span className={classes.statementName}>尺寸：</span>
                <span className={classes.statementValue}>{v.fullSize}</span>
              </Grid>
              <Grid item md={5} xs={5}>
                <span className={classes.statementName}>庫位：</span>
                <span className={classes.statementValue}>{v.price}</span>
              </Grid>
              <Grid item md={2} xs={2}></Grid>
              <Grid item md={5} xs={5}>
                <span className={classes.statementName}>售價：</span>
                <span className={classes.statementValue}>{v.sell}</span>
              </Grid>
              <Grid item md={5} xs={5}>
                <span className={classes.statementName}>備註：</span>
                <span className={classes.statementValue}>{v.memo}</span>
              </Grid>
            </Grid>
          );
        })}

        <Grid item md={12} xs={12}>
          <div className={classes.statementTitle}>維修保養項目</div>
        </Grid>
        {repairInfo.length ? (
          repairInfo.map((v) => {
            if (v.name) {
              return (
                <Grid container spacing={1} key={v.index} className={classes.statementItem}>
                  <Grid item md={2} xs={2}>
                    <span className={classes.statementName}>{v.index + 1}.</span>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>項目：</span>
                    <span className={classes.statementValue}>{v.name}</span>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>數量：</span>
                    <span className={classes.statementValue}>{v.amount}</span>
                  </Grid>
                  <Grid item md={2} xs={2}></Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>價格：</span>
                    <span className={classes.statementValue}>{v.price}</span>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>備註：</span>
                    <span className={classes.statementValue}>{v.memo}</span>
                  </Grid>
                </Grid>
              );
            }
          })
        ) : (
          <span className={classes.statementValue}>無資料</span>
        )}

        <Grid item md={12} xs={12}>
          <div className={classes.statementTitle}>定位工資</div>
        </Grid>
        {pAndPInfo.length ? (
          pAndPInfo.map((v) => {
            if (v.type) {
              return (
                <Grid container spacing={1} key={v.index} className={classes.statementItem}>
                  <Grid item md={2} xs={2}>
                    <span className={classes.statementName}>{v.index + 1}.</span>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>類型：</span>
                    <span className={classes.statementValue}>{v.type}</span>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>項目：</span>
                    <span className={classes.statementValue}>{v.content}</span>
                  </Grid>
                  <Grid item md={2} xs={2}></Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>價格：</span>
                    <span className={classes.statementValue}>{v.price}</span>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>數量：</span>
                    <span className={classes.statementValue}>{v.amount}</span>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <span className={classes.statementName}>備註：</span>
                    <span className={classes.statementValue}>{v.memo}</span>
                  </Grid>
                </Grid>
              );
            }
          })
        ) : (
          <span className={classes.statementValue}>無資料</span>
        )}

        <Grid container spacing={2} className={classes.statementGrid}>
          <Grid item md={12} xs={12}>
            <div className={classes.statementTitle}>金額總計</div>
          </Grid>
          <Grid item md={5} xs={5}>
            <span className={classes.statementName}>統編：</span>
            <span className={classes.statementValue}>
              {totalInfo.isInvoice == '需要發票' ? totalInfo.taxID : '無'}
            </span>
          </Grid>
          <Grid item md={6} xs={6}>
            <span className={classes.statementName}>公司行號：</span>
            <span className={classes.statementValue}>
              {totalInfo.isInvoice == '需要發票' ? totalInfo.company : '無'}
            </span>
          </Grid>
          <Grid item md={5} xs={5}>
            <span className={classes.statementName}>收件人：</span>
            <span className={classes.statementValue}>
              {totalInfo.isInvoice == '需要發票' ? totalInfo.receiver : '無'}
            </span>
          </Grid>
          <Grid item md={6} xs={6}>
            <span className={classes.statementName}>發票地址：</span>
            <span className={classes.statementValue}>
              {totalInfo.isInvoice == '需要發票' ? totalInfo.address : '無'}
            </span>
          </Grid>
          <Grid item md={5} xs={5}>
            <span className={classes.statementName}>金額加總：</span>
            <span className={classes.statementValue}>{totalInfo.totalPrice}</span>
          </Grid>
          <Grid item md={6} xs={6}>
            <span className={classes.statementName}>不印工單：</span>
            <span className={classes.statementValue}>{totalInfo.noPrint ? '是' : '否'}</span>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <div className={classes.statementTitle}>付款方式</div>
        </Grid>
        {paymentInfo.length ? (
          <Grid container spacing={2} className={classes.statementItem}>
            {paymentInfo.map((v, i) => {
              return (
                <Grid item key={i} md={5} xs={5}>
                  <span className={classes.statementName}>{transferLabel(v.method)}：</span>
                  <span className={classes.statementValue}>{v.money}</span>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <span className={classes.statementValue}>無資料</span>
        )}

        <Grid item md={12} xs={12}>
          <div className={classes.statementTitle}>客戶簽名</div>
        </Grid>
        {basicInfo.ticketNo ? (
          <Grid container spacing={2} className={classes.statementItem}>
            <img
              src={`${ApiUrl}images/ticketSign/${basicInfo.ticketNo}_sign.png`}
              alt="signature"
              style={{
                display: 'block',
                margin: '10px 50px auto',
                border: '1px solid black',
                width: '600px',
                height: '300px',
              }}
            />
          </Grid>
        ) : (
          <span className={classes.statementValue}>無資料</span>
        )}

        <Grid item md={12} xs={12}>
          <div className={classes.statementTitle}>車體標記</div>
        </Grid>
        {basicInfo.ticketNo ? (
          <Grid container spacing={2} className={classes.statementItem}>
            <img
              src={`${ApiUrl}images/carMark/${basicInfo.ticketNo}_car.png`}
              alt="signature"
              style={{
                display: 'block',
                margin: '10px 50px auto',
                border: '1px solid black',
                width: '350px',
                height: '350px',
              }}
            />
          </Grid>
        ) : (
          <span className={classes.statementValue}>無資料</span>
        )}
      </DialogContent>
      <DialogActions>
        {ticketData.ticketType == 'Prepay' ? (
          <Button variant="contained" onClick={createByPrepay} className={classes.submitBtn}>
            產生一般工單
          </Button>
        ) : null}
        {userStatus.info.authority === 0 ||
        userStatus.info.authority === 1 ||
        userStatus.info.authority === 2 ? (
          <Button color="secondary" variant="contained" onClick={handleAnnulTicket}>
            作廢
          </Button>
        ) : null}
        <Button color="primary" variant="contained" onClick={handleModalClose}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TicketDetailModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  ticketData: PropTypes.object,
};

export default TicketDetailModal;
