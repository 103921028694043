import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CircularProgress, FormControlLabel, Checkbox } from '@material-ui/core';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import LocationField from 'components/Dropdown/LocationField';
import UsersField from 'components/Dropdown/UsersField';
import ShiftField from 'components/Dropdown/ShiftField';
import DateField from 'components/Dropdown/DateField';
import styles from './TabStyle';

const useStyles = makeStyles(styles);

const BasicTab = (props) => {
  const classes = useStyles();
  const { ticketData, basicInfo, setTicketData } = props;
  const userStatus = useSelector((state) => state.user);
  // const { test, setTest } = useState('');
  // const dispatch = useDispatch();
  const updateBasicInfo = useCallback(
    (v) => {
      let newData = { ...ticketData };
      // 切換地點&日期時產生單號
      if (Object.keys(v)[0] == 'location') {
        newData.basicInfo.ticketNo = createTicketNo(v.location, basicInfo.date);
      }
      if (Object.keys(v)[0] == 'date') {
        newData.basicInfo.ticketNo = createTicketNo(basicInfo.location, v.date);
      }
      if (Object.keys(v)[0] == 'isEmployee') {
        if (Object.entries(v)[0][1]) {
          newData.basicInfo.ticketNo = '員工' + newData.basicInfo.ticketNo;
        } else {
          newData.basicInfo.ticketNo = newData.basicInfo.ticketNo.replace('員工', '');
        }
      }
      newData.basicInfo = { ...basicInfo, ...v };
      setTicketData(newData);
    },
    [ticketData],
  );

  const transLocation = (location) => {
    let transfer = '';
    switch (location) {
      case '總廠':
        transfer = 'Z';
        break;
      case '板橋':
        transfer = 'A';
        break;
      case '新店':
        transfer = 'B';
        break;
      case '泰山':
        transfer = 'C';
        break;
      case '梧棲':
        transfer = 'E';
        break;
      case '松山':
        transfer = 'F';
        break;
      case '桃園':
        transfer = 'H';
        break;
      case '土城':
        transfer = 'K';
        break;
      case '北屯':
        transfer = 'L';
        break;
      case '南屯':
        transfer = 'M';
        break;
      case '林口':
        transfer = 'N';
        break;
      default:
        break;
    }
    return transfer;
  };

  const createTicketNo = (location, date) => {
    let en = transLocation(location);
    let em = basicInfo.isEmployee ? '員工' : '';
    return en ? em + en + dayjs(date).format('YYYYMMDD') : '單號格式有誤';
  };

  return (
    <>
      {ticketData && basicInfo ? (
        <div className={classes.content}>
          <div className={classes.main}>
            <div className={classes.title}>{'基本資料'}</div>
            <div className={classes.column}>
              <div className={classes.fieldTitle}>{'服務地點：'}</div>
              <LocationField
                val={basicInfo.location}
                valChange={(location) => updateBasicInfo({ location })}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={basicInfo.isEmployee}
                    checked={basicInfo.isEmployee}
                    onChange={(e) => updateBasicInfo({ isEmployee: e.target.checked })}
                    name="isEmployee"
                    color="primary"
                  />
                }
                label="員工單"
              />
            </div>
            <div className={classes.column}>
              <div className={classes.fieldTitle}>{'工單單號：'}</div>
              <div className={classes.fieldText}>{basicInfo.ticketNo}</div>
            </div>
            <div className={classes.column}>
              <div className={classes.fieldTitle}>{'日期：'}</div>
              <DateField
                disabled={userStatus.info.authority === 2 || userStatus.info.authority === 3}
                value={basicInfo.date}
                onChange={(date) => updateBasicInfo({ date })}
                label="日期"
              />
            </div>
            <div className={classes.column}>
              <div className={classes.fieldTitle}>{'結帳技師：'}</div>
              <div className={classes.fieldText}>
                {basicInfo.checkoutTechID}({basicInfo.checkoutTechName})
              </div>
            </div>
            <div className={classes.column}>
              <div className={classes.fieldTitle}>{'班別：'}</div>
              <ShiftField
                disabled={userStatus.info.authority === 2 || userStatus.info.authority === 3}
                val={basicInfo.shifts}
                valChange={(shifts) => updateBasicInfo({ shifts })}
              />
            </div>
            <div className="w-100"></div>
            <div className={classes.column} style={{ flexWrap: 'wrap' }}>
              <div className={classes.fieldTitle}>{'維修技師：'}</div>
              <UsersField
                multiple={true}
                isApp={false}
                valChange={(repairTech) => updateBasicInfo({ repairTech })}
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative', marginLeft: '50%', left: '-20px' }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

BasicTab.propTypes = {
  ticketData: PropTypes.object,
  basicInfo: PropTypes.object,
  setTicketData: PropTypes.func,
};

export default BasicTab;
