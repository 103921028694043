// const ApiUrl = 'https://localhost:4000/';
// const ApiUrl = 'http://104.155.205.215:4000/';
const ApiUrl = 'https://api.tiremaster-24h-services.com/';
const Api = {
  Tires: {
    SearchTires: `${ApiUrl}db/searchTires`,
    CreateTire: `${ApiUrl}db/createTire`,
    EditTire: `${ApiUrl}db/editTire`,
    DeleteTire: `${ApiUrl}db/deleteTire`,
    GetTireId: `${ApiUrl}db/getTireId`,
    GetTireById: `${ApiUrl}db/getTireById`,
    GetTireLocation: `${ApiUrl}db/getTireLocation`,
    UpdateTireLocation: `${ApiUrl}db/updateTireLocation`,
    CheckTireExist: `${ApiUrl}db/checkTireExist`,
    CreateRecord: `${ApiUrl}db/createRecord`,
    SearchRecords: `${ApiUrl}db/searchRecords`,
    GetRecords: `${ApiUrl}db/getRecords`,
    GetGraphByWidth: `${ApiUrl}db/getGraphByWidth`,
    GetStatistics: `${ApiUrl}db/getStatistics`,
    UpdateTireStatus: `${ApiUrl}db/updateTireStatus`,
    UpdateTireShippingDate: `${ApiUrl}db/updateTireShippingDate`,
  },
  Ticket: {
    GetUserInfo: `${ApiUrl}ticket/getUserInfo`,
    GetAllTechs: `${ApiUrl}ticket/getAllTechs`,
    GetAllAppTechs: `${ApiUrl}ticket/getAllAppTechs`,
    GetTirePrice: `${ApiUrl}ticket/getTirePrice`,
    CreateFirst: `${ApiUrl}ticket/createFirst`,
    CreateRest: `${ApiUrl}ticket/createRest`,
    CheckIsTodayFirst: `${ApiUrl}ticket/checkIsTodayFirst`,
    GetLastestID: `${ApiUrl}ticket/getLastestID`,
    SearchTickets: `${ApiUrl}ticket/searchTickets`,
    GetStatistics: `${ApiUrl}ticket/getStatistics`,
    UpdateTicketStatus: `${ApiUrl}ticket/updateTicketStatus`,
    UpdateTicketMemo: `${ApiUrl}ticket/updateTicketMemo`,
    CheckDuplicate: `${ApiUrl}ticket/checkDuplicate`,
  },
  Client: {
    GetClientInfo: `${ApiUrl}client/getClientInfo`,
    GetClientByLicenseNo: `${ApiUrl}client/getClientByLicenseNo`,
    CheckClientExist: `${ApiUrl}client/checkClientExist`,
    CreateClientInfo: `${ApiUrl}client/createClientInfo`,
    UpdateClientInfo: `${ApiUrl}client/updateClientInfo`,
  },
  User: {
    Check: `${ApiUrl}user/check/`,
    Logout: `${ApiUrl}user/logout/`,
  },
};

const AxiosConfig = {
  User: {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
  },
  General: {
    // withCredentials: true,
    headers: {},
  },
};

const SnackbarType = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export { ApiUrl, Api, AxiosConfig, SnackbarType };
