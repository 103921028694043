import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import styles from './TabStyle';

const useStyles = makeStyles(styles);

const SuggestTab = (props) => {
  const classes = useStyles();
  const { ticketData, suggestInfo, setTicketData } = props;

  const updateSuggestInfo = useCallback(
    (v) => {
      let newData = { ...ticketData };
      newData.suggestInfo = v;
      setTicketData(newData);
    },
    [ticketData],
  );

  return (
    <>
      <div className={classes.content}>
        <div className={classes.main}>
          <div className={classes.title}>{'建議事項'}</div>
          <div className={classes.cardColumn}>
            <TextField
              className={classes.multiTextField}
              label="請輸入"
              variant="outlined"
              size="medium"
              value={suggestInfo}
              onChange={(e) => updateSuggestInfo(e.target.value)}
              multiline
              fullWidth
              minRows={3}
            />
          </div>
        </div>
      </div>
    </>
  );
};

SuggestTab.propTypes = {
  ticketData: PropTypes.object,
  suggestInfo: PropTypes.string,
  setTicketData: PropTypes.func,
};

export default SuggestTab;
