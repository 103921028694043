import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Switch, Redirect } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserLogin } from 'redux/actions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Search from 'pages/Search';
import Statistics from 'pages/Statistics';
import CommonTicket from 'pages/CommonTicket';
import PrepayTicket from 'pages/PrepayTicket';
import Login from 'pages/Login';
import Home from 'pages/Home';
import Header from 'components/Header';
// import Footer from 'components/Footer';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Snackbar from 'components/Snackbar';
import './styles/main.scss';

const history = createBrowserHistory();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[600],
  },
  appContent: {
    marginTop: '135px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '75px',
    },
  },
}));

function App() {
  const classes = useStyles();
  const userStatus = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserLogin());
  }, [dispatch, userStatus.isLogin]);

  return (
    <Router history={history}>
      <CssBaseline />
      <div className={classes.root}>
        <PerfectScrollbar>
          <Header title="輪胎大師工單系統" />
          <div className={classes.appContent}>
            {!userStatus.isLoading && (
              <Switch>
                {userStatus.isLogin && <Search exact path="/search" />}
                {userStatus.isLogin && <Statistics exact path="/statistics" />}
                {userStatus.isLogin && <CommonTicket exact path="/commonTicket/:type" />}
                {userStatus.isLogin && <PrepayTicket exact path="/prepayTicket" />}
                {userStatus.isLogin && <Home exact path="/" />}
                {userStatus.isLogin && <Redirect to="/" />}
                {!userStatus.isLogin && <Login path="/login" />}
                {!userStatus.isLogin && <Redirect to="/login" component={Login} />}
              </Switch>
            )}
          </div>
          <Snackbar />
        </PerfectScrollbar>
      </div>
    </Router>
  );
}

export default App;
