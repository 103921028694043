import React, { useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import { Api, AxiosConfig } from 'GlobalDefine';

// import { useDispatch } from 'react-redux';
// import { openErrorSnackbar } from 'redux/actions';
import styles from './TabStyle';

const useStyles = makeStyles(styles);

const NewTireTab = (props) => {
  const classes = useStyles();
  const { ticketData, tireInfo, setTicketData } = props;
  // const dispatch = useDispatch();
  const tableRef = useRef();

  const updateTireInfo = useCallback(
    (v, updIndex) => {
      let newData = { ...ticketData };
      const currentItem = tireInfo.find((el) => el.index == updIndex);
      const currentIndex = tireInfo.findIndex((el) => el.index == updIndex);
      newData.tireInfo[currentIndex] = { ...currentItem, ...v };
      setTicketData(newData);
    },
    [ticketData],
  );

  const addBlankRow = () => {
    let newTicketData = { ...ticketData };
    let last = [...tireInfo].pop();
    newTicketData.tireInfo = [
      ...tireInfo,
      {
        index: last ? last.index + 1 : 0,
        tireType: '新胎',
        number: '',
        fullSize: '',
        price: '',
        sell: '',
        memo: '',
        brand: '',
        amount: 0,
        warn: '',
      },
    ];
    setTicketData(newTicketData);
  };

  const removeLastRow = () => {
    let newTicketData = { ...ticketData };
    const newTires = [...tireInfo].filter((el) => el.tireType == '新胎');
    let last = [...newTires].pop();
    newTicketData.tireInfo = tireInfo.filter((el) => el.index !== last.index);
    setTicketData(newTicketData);
  };

  useEffect(() => {}, []);

  return (
    <>
      {ticketData && tireInfo ? (
        <div className={classes.content}>
          <div className={classes.main}>
            <div className={classes.title}>{'新胎更換項目'}</div>
            <div className={classes.cardColumn}>
              <TableContainer component={Paper} elevation={4} style={{ marginTop: '20px' }}>
                <Table ref={tableRef}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.headerCell} align="center">
                        廠商
                      </TableCell>
                      <TableCell className={classes.headerCell__large} align="center">
                        尺寸
                      </TableCell>
                      <TableCell className={classes.headerCell__medium} align="center">
                        數量
                      </TableCell>
                      <TableCell className={classes.headerCell__medium} align="center">
                        售價
                      </TableCell>
                      <TableCell className={classes.headerCell__medium} align="center">
                        備註
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tireInfo
                      .filter((el) => el.tireType == '新胎')
                      .map((v, i) => (
                        <TableRow key={i} className={classes.bodyRow}>
                          <TableCell className={classes.bodyCell} align="center">
                            <TextField
                              className={classes.cellText}
                              variant="outlined"
                              size="small"
                              value={v.brand}
                              onChange={(e) => updateTireInfo({ brand: e.target.value }, v.index)}
                            />
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="center">
                            <TextField
                              className={classes.cellText}
                              variant="outlined"
                              size="small"
                              value={v.fullSize}
                              onChange={(e) =>
                                updateTireInfo({ fullSize: e.target.value }, v.index)
                              }
                              multiline
                            />
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="center">
                            <TextField
                              className={classes.cellText}
                              variant="outlined"
                              size="small"
                              value={v.amount}
                              onChange={(e) => updateTireInfo({ amount: e.target.value }, v.index)}
                              multiline
                            />
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="center">
                            <TextField
                              className={classes.cellText}
                              variant="outlined"
                              size="small"
                              value={v.sell}
                              onChange={(e) => updateTireInfo({ sell: e.target.value }, v.index)}
                            />
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="center">
                            <TextField
                              className={classes.cellText}
                              variant="outlined"
                              size="small"
                              value={v.memo}
                              onChange={(e) => updateTireInfo({ memo: e.target.value }, v.index)}
                              multiline
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className={classes.tableControlBtns}>
              <Grid container spacing={2}>
                <Grid item md={5} xs={5} align="center">
                  <IconButton className={classes.smallRemoveBtn} onClick={removeLastRow}>
                    <Remove fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item md={5} xs={5} align="center">
                  <IconButton className={classes.smallAddBtn} onClick={addBlankRow}>
                    <Add fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative', marginLeft: '50%', left: '-20px' }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

NewTireTab.propTypes = {
  ticketData: PropTypes.object,
  tireInfo: PropTypes.array,
  setTicketData: PropTypes.func,
};

export default NewTireTab;
