import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import InputTextField from '../../components/Ticket/InputTextField';
import UsersField from '../../components/Dropdown/UsersField';
import styles from './TabStyle';

const useStyles = makeStyles(styles);

const AppTab = (props) => {
  const classes = useStyles();
  const { ticketData, appInfo, setTicketData } = props;

  const updateAppInfo = useCallback(
    (v) => {
      let newData = { ...ticketData };
      newData.appInfo = { ...appInfo, ...v };
      setTicketData(newData);
    },
    [ticketData],
  );

  return (
    <>
      {ticketData && appInfo ? (
        <div className={classes.content}>
          <div className={classes.main}>
            <div className={classes.title}>{'App (員工介紹)'}</div>
            <div className={classes.appColumn}>
              <div className={classes.fieldTitle}>{'員工代號：'}</div>
              <UsersField
                isApp={true}
                multiple={false}
                valChange={(appTech) => updateAppInfo({ appTech })}
              />
              <div className="w-100"></div>
              <div className={classes.matchTech_app}>{appInfo.techName}</div>
            </div>
            <div className={classes.appColumn}>
              <div className={classes.fieldTitle}>{'備註：'}</div>
              <InputTextField
                val={appInfo.memo}
                valChange={(memo) => updateAppInfo({ memo })}
                label="備註"
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative', marginLeft: '50%', left: '-20px' }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

AppTab.propTypes = {
  ticketData: PropTypes.object,
  appInfo: PropTypes.object,
  setTicketData: PropTypes.func,
};

export default AppTab;
