const statisticsTabStyle = (theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  title: {
    fontSize: '24px',
    textAlign: 'center',
    width: '100%',
    margin: '20px 5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      margin: '10px 5px',
    },
  },
  divideline: {
    height: '2px',
    padding: '0px 30px',
    margin: '40px 0',
    width: '100%',
    borderBottom: '2px solid black',
  },
  searchButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0px',
  },
  printButton: {
    marginLeft: '10px',
    backgroundColor: '#2e7d32',
    color: 'white',
  },
  center: {
    position: 'relative',
    marginLeft: '50%',
    left: '-20px',
  },
  row: {
    padding: '10px 20px',
  },
});

export default statisticsTabStyle;
