import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { isEmpty, isArray } from 'lodash';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import { Api, AxiosConfig } from 'GlobalDefine';
import CheckboxLabels from './CheckboxLabels';
import DateField from 'components/Dropdown/DateField';
import LocationField from 'components/Dropdown/LocationField';
import ShiftField from '../../components/Dropdown/ShiftField';
import InputTextField from '../../components/Ticket/InputTextField';
import SearchResultTable from './SearchResultTable';
import styles from './searchTabStyle';
import dayjs from 'dayjs';

Date.prototype.yyyymmdd = function () {
  var mm = this.getMonth() + 1;
  var dd = this.getDate();
  return [this.getFullYear(), '-', (mm > 9 ? '' : '0') + mm, '-', (dd > 9 ? '' : '0') + dd].join(
    '',
  );
};

const useStyles = makeStyles(styles);

export default function SearchTab() {
  const classes = useStyles();
  const [search, setSearch] = useState({
    ticketDate: '',
    ticketNo: '',
    location: '',
    shift: '',
  });

  const [labels, setLabels] = useState({
    ticketDate: false,
    ticketNo: false,
    location: false,
    shift: false,
  });

  const [loading, setLoading] = useState(false);

  const updateSearchArgs = useCallback(
    (state) => {
      setSearch({ ...search, ...state });
    },
    [search],
  );

  const [searchResult, setSearchResult] = useState([]);

  const startSearch = useCallback(
    async (e) => {
      setLoading(true);
      if (e && e.cancelable) {
        e.preventDefault();
      }
      try {
        const submit = {
          ticketDate: labels.ticketDate ? search.ticketDate : '',
          ticketNo: labels.ticketNo ? search.ticketNo : '',
          location: labels.location ? search.location : '',
          shift: labels.shift ? search.shift : '',
        };
        const res = await axios.post(`${Api.Ticket.SearchTickets}`, submit, AxiosConfig.General);
        const newData = res.data.map((v) => {
          v.ticketDate = dayjs(v.date).format('YYYY-MM-DD');
          v.repairTech = JSON.parse(v.repairTech);
          v.checkoutTech = JSON.parse(v.checkoutTech);
          v.clientInfo = JSON.parse(v.clientInfo);
          v.tireInfo = JSON.parse(v.tireInfo);
          v.repairInfo = JSON.parse(v.repairInfo);
          v.pAndPInfo = JSON.parse(v.pAndPInfo);
          v.suggestInfo = JSON.parse(v.suggestInfo);
          v.appInfo = JSON.parse(v.appInfo);
          v.totalInfo = JSON.parse(v.totalInfo);
          v.paymentInfo = JSON.parse(v.paymentInfo);
          return v;
        });
        console.log('search:', newData);
        setSearchResult(newData);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
    [search, labels],
  );

  return (
    <>
      <form onSubmit={startSearch}>
        <div className={classes.main}>
          <div className={classes.row}>
            <CheckboxLabels setLabelFunc={setLabels} />
          </div>
          {Object.values(labels).some((value) => value === true) && (
            <div className={classes.row}>
              {labels.ticketDate && (
                <DateField
                  value={search.ticketDate}
                  onChange={(ticketDate) => updateSearchArgs({ ticketDate })}
                  label="工單日期"
                />
              )}
              {labels.location && (
                <LocationField
                  val={search.location}
                  valChange={(location) => updateSearchArgs({ location })}
                />
              )}
              {labels.shift && (
                <ShiftField val={search.shift} valChange={(shift) => updateSearchArgs({ shift })} />
              )}
              {labels.ticketNo && (
                <InputTextField
                  val={search.ticketNo}
                  valChange={(ticketNo) => updateSearchArgs({ ticketNo })}
                  label="工單號碼"
                />
              )}
            </div>
          )}
        </div>
        <div className={classes.searchButton}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            startIcon={<Search />}
          >
            查詢
          </Button>
        </div>
      </form>
      {loading && (
        <div className={classes.center}>
          <CircularProgress />
        </div>
      )}
      {!loading && !isEmpty(searchResult) && isArray(searchResult) && (
        <div>
          <SearchResultTable result={searchResult} refresh={startSearch} />
        </div>
      )}
    </>
  );
}
