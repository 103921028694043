import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';

const CheckboxLabels = ({ setLabelFunc }) => {
  const [state, setState] = useState({
    ticketDate: false,
    ticketNo: false,
    location: false,
    shift: false,
  });

  const handleChange = (event) => {
    if (event.target.name === 'ticketNo' && event.target.checked === true) {
      setState({
        ticketDate: false,
        ticketNo: true,
        location: false,
        shift: false,
      });
    } else {
      setState({ ...state, [event.target.name]: event.target.checked });
    }
  };

  useEffect(() => {
    if (!isEmpty(state)) {
      setLabelFunc(state);
    }
  }, [state]);

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={state.ticketDate}
            onChange={handleChange}
            name="ticketDate"
            color="primary"
          />
        }
        label="工單日期"
        disabled={state.ticketNo}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.location}
            onChange={handleChange}
            name="location"
            color="primary"
          />
        }
        label="地點"
        disabled={state.ticketNo}
      />
      <FormControlLabel
        control={
          <Checkbox checked={state.shift} onChange={handleChange} name="shift" color="primary" />
        }
        label="班別"
        disabled={state.ticketNo}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.ticketNo}
            onChange={handleChange}
            name="ticketNo"
            color="primary"
          />
        }
        label="編號"
      />
    </FormGroup>
  );
};

CheckboxLabels.propTypes = {
  setLabelFunc: PropTypes.func,
};

export default CheckboxLabels;
