import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { isEmpty, isArray } from 'lodash';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search, CloudDownload, Print } from '@material-ui/icons';
import { Api, AxiosConfig } from 'GlobalDefine';
import CheckboxLabels from './CheckboxLabels';
import DateField from 'components/Dropdown/DateField';
import LocationField from 'components/Dropdown/LocationField';
import ShiftField from 'components/Dropdown/ShiftField';
import StatisticsResultTable from './StatisticsResultTable';
import StatisticsPrintModal from '../../components/Ticket/StatisticsPrintModal';
import styles from './statisticsTabStyle';
import { useDispatch } from 'react-redux';
import { openErrorSnackbar } from 'redux/actions';
import dayjs from 'dayjs';
import XlsxPopulate from 'xlsx-populate';

Date.prototype.yyyymmdd = function () {
  var mm = this.getMonth() + 1;
  var dd = this.getDate();
  return [this.getFullYear(), '-', (mm > 9 ? '' : '0') + mm, '-', (dd > 9 ? '' : '0') + dd].join(
    '',
  );
};

const useStyles = makeStyles(styles);

export default function SearchTab() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showResult, setShowResult] = useState(false);
  const [oneDate, setOneDate] = useState('');
  const [search, setSearch] = useState({
    start_date: '',
    end_date: '',
    location: '',
    shift: '',
  });

  const [labels, setLabels] = useState({
    start_date: false,
    end_date: false,
    location: false,
    shift: false,
  });

  const [loading, setLoading] = useState(false);
  const [selectShift, setSelectShift] = useState('');
  const [selectLocation, setSelectLocation] = useState('');
  const [openPrint, setOpenPrint] = useState(false);
  const [dayTickets, setDayTickets] = useState(null);

  const handlePrintOpen = () => setOpenPrint(true);
  const handlePrintClose = () => {
    setOpenPrint(false);
    setDayTickets(null);
  };

  const updateSearchArgs = useCallback(
    (state) => {
      setSearch({ ...search, ...state });
      setShowResult(false);
    },
    [search],
  );

  const [searchResult, setSearchResult] = useState([]);

  const startSearch = useCallback(
    async (e) => {
      setLoading(true);
      if (e && e.cancelable) e.preventDefault();
      try {
        const submit = {
          start_date: labels.start_date ? search.start_date : '',
          end_date: labels.end_date ? search.end_date : '',
          location: labels.location ? search.location : '',
          shift: labels.shift ? search.shift : '',
        };
        const res = await axios.post(`${Api.Ticket.GetStatistics}`, submit, AxiosConfig.General);
        const results = await Promise.all(
          res.data.map((v) => {
            v.ticketDate = dayjs(v.date).format('MM/DD');
            v.repairTech = JSON.parse(v.repairTech);
            v.checkoutTech = JSON.parse(v.checkoutTech);
            v.clientInfo = JSON.parse(v.clientInfo);
            v.tireInfo = JSON.parse(v.tireInfo);
            v.repairInfo = JSON.parse(v.repairInfo);
            v.pAndPInfo = JSON.parse(v.pAndPInfo);
            v.suggestInfo = JSON.parse(v.suggestInfo);
            v.appInfo = JSON.parse(v.appInfo);
            v.totalInfo = JSON.parse(v.totalInfo);
            v.paymentInfo = JSON.parse(v.paymentInfo);
            return v;
          }),
        );

        // 2023-10-02 因應工單新增廠商資料，把沒有廠商的輪胎項目加上廠商
        for (let item of results) {
          for (let i = 0; i < item.tireInfo.length; i++) {
            const tireId = item.tireInfo[i].number;
            const firm = item.tireInfo[i].firm;
            if (tireId && !firm) {
              const r = await axios.get(`${Api.Tires.GetTireById}/${tireId}`, AxiosConfig.General);
              if (r.data) item.tireInfo[i].firm = r.data[0].firm;
            }
          }
        }

        setSearchResult(results);
        setLoading(false);
        setShowResult(true);
      } catch (error) {
        console.log(error);
        dispatch(openErrorSnackbar('錯誤！請聯繫維護人員！'));
      }
    },
    [search, labels],
  );

  const getReportBlob = async () => {
    const data = [];
    const filename = `工單統計報表.xlsx`;
    let wb = await XlsxPopulate.fromBlankAsync();
    let tab = wb.sheet(0).name('new');
    const transferName = (arr) => {
      let str = '';
      arr.forEach((v) => (str += `${v.techName} `));
      return str;
    };
    const transferPay = (info, method) => {
      const findOne = info.find((el) => el.method == method);
      return findOne ? findOne.money : '';
    };
    for (let item of searchResult) {
      // 建議事項
      if (item.suggestInfo) {
        data.push([
          item.ticketNo,
          item.ticketDate,
          item.shift,
          item.clientLicenseNo,
          item.clientInfo.clientName,
          item.clientInfo.clientPhone,
          '建議事項',
          1,
          0,
          0,
          '',
          '',
          '',
          '',
          '',
          item.totalInfo.discount == 'reserve' ? 'V' : '',
          item.totalInfo.discount == 'warranty' ? 'V' : '',
          item.appInfo.appTech ? item.appInfo.appTech.techName : '',
          transferPay(item.paymentInfo, 'cash'),
          transferPay(item.paymentInfo, 'credit_card'),
          transferPay(item.paymentInfo, 'remit'),
          transferPay(item.paymentInfo, 'monthly'),
          transferPay(item.paymentInfo, 'gift_voucher'),
          transferPay(item.paymentInfo, 'gov_voucher'),
          transferName(item.repairTech),
          item.checkoutTech.techName,
          item.suggestInfo,
          item.totalInfo.taxID,
          item.totalInfo.company,
          item.totalInfo.receiver ? item.totalInfo.receiver : '',
          item.totalInfo.address,
          '',
          '',
          '',
          item.status == '作廢' ? `作廢 (${item.ticketMemo})` : '',
        ]);
      }
      // 加入營業稅欄位
      if (
        !item.totalInfo.noPrint &&
        !item.totalInfo.extra &&
        item.totalInfo.isInvoice !== '不需要發票'
      ) {
        data.push([
          item.ticketNo,
          item.ticketDate,
          item.shift,
          item.clientLicenseNo,
          item.clientInfo.clientName,
          item.clientInfo.clientPhone,
          '營業稅',
          1,
          item.totalInfo.subPrice ? Math.ceil(item.totalInfo.subPrice * 0.05) : 0,
          item.totalInfo.subPrice ? Math.ceil(item.totalInfo.subPrice * 0.05) : 0,
          '',
          '',
          '',
          '',
          '',
          item.totalInfo.discount == 'reserve' ? 'V' : '',
          item.totalInfo.discount == 'warranty' ? 'V' : '',
          item.appInfo.appTech ? item.appInfo.appTech.techName : '',
          transferPay(item.paymentInfo, 'cash'),
          transferPay(item.paymentInfo, 'credit_card'),
          transferPay(item.paymentInfo, 'remit'),
          transferPay(item.paymentInfo, 'monthly'),
          transferPay(item.paymentInfo, 'gift_voucher'),
          transferPay(item.paymentInfo, 'gov_voucher'),
          transferName(item.repairTech),
          item.checkoutTech.techName,
          item.suggestInfo,
          item.totalInfo.taxID,
          item.totalInfo.company,
          item.totalInfo.receiver ? item.totalInfo.receiver : '',
          item.totalInfo.address,
          '',
          '',
          '',
          item.status == '作廢' ? `作廢 (${item.ticketMemo})` : '',
        ]);
      }
      // 加入折扣欄位
      if (item.totalInfo.discount == 'discount') {
        data.push([
          item.ticketNo,
          item.ticketDate,
          item.shift,
          item.clientLicenseNo,
          item.clientInfo.clientName,
          item.clientInfo.clientPhone,
          '折扣',
          1,
          item.totalInfo.discountAmount ? -parseInt(item.totalInfo.discountAmount) : 0,
          item.totalInfo.discountAmount ? -parseInt(item.totalInfo.discountAmount) : 0,
          '',
          '',
          '',
          '',
          item.totalInfo.discountAmount ? parseInt(item.totalInfo.discountAmount) : '',
          item.totalInfo.discount == 'reserve' ? 'V' : '',
          item.totalInfo.discount == 'warranty' ? 'V' : '',
          item.appInfo.appTech ? item.appInfo.appTech.techName : '',
          transferPay(item.paymentInfo, 'cash'),
          transferPay(item.paymentInfo, 'credit_card'),
          transferPay(item.paymentInfo, 'remit'),
          transferPay(item.paymentInfo, 'monthly'),
          transferPay(item.paymentInfo, 'gift_voucher'),
          transferPay(item.paymentInfo, 'gov_voucher'),
          transferName(item.repairTech),
          item.checkoutTech.techName,
          item.suggestInfo,
          item.totalInfo.taxID,
          item.totalInfo.company,
          item.totalInfo.receiver ? item.totalInfo.receiver : '',
          item.totalInfo.address,
          '',
          '',
          '',
          item.status == '作廢' ? `作廢 (${item.ticketMemo})` : '',
        ]);
      }
      // 維修保養項目
      item.repairInfo.forEach((v) => {
        if (v.name) {
          data.push([
            item.ticketNo,
            item.ticketDate,
            item.shift,
            item.clientLicenseNo,
            item.clientInfo.clientName,
            item.clientInfo.clientPhone,
            v.name,
            v.amount,
            v.price ? v.price : 0,
            v.amount * v.price,
            '',
            '',
            '',
            '',
            '',
            item.totalInfo.discount == 'reserve' ? 'V' : '',
            item.totalInfo.discount == 'warranty' ? 'V' : '',
            item.appInfo.appTech ? item.appInfo.appTech.techName : '',
            transferPay(item.paymentInfo, 'cash'),
            transferPay(item.paymentInfo, 'credit_card'),
            transferPay(item.paymentInfo, 'remit'),
            transferPay(item.paymentInfo, 'monthly'),
            transferPay(item.paymentInfo, 'gift_voucher'),
            transferPay(item.paymentInfo, 'gov_voucher'),
            transferName(item.repairTech),
            item.checkoutTech.techName,
            v.memo,
            item.totalInfo.taxID,
            item.totalInfo.company,
            item.totalInfo.receiver ? item.totalInfo.receiver : '',
            item.totalInfo.address,
            '',
            '',
            '',
            item.status == '作廢' ? `作廢 (${item.ticketMemo})` : '',
          ]);
        }
      });
      // 輪胎維修項目
      item.tireInfo.forEach((v) => {
        if (v.number || v.brand) {
          data.push([
            item.ticketNo,
            item.ticketDate,
            item.shift,
            item.clientLicenseNo,
            item.clientInfo.clientName,
            item.clientInfo.clientPhone,
            v.tireType == '新胎' ? `新胎${v.fullSize}` : v.fullSize,
            v.amount ? v.amount : 1,
            v.tireType == '新胎' ? v.sell : v.price * 100,
            v.amount * v.sell,
            v.tireType == '新胎' ? `新胎${v.brand}` : v.brand,
            v.firm || '',
            v.tireType == '中古胎' ? v.sell * 0.4 : '',
            v.tireType == '中古胎' ? v.price * 100 : '',
            '',
            item.totalInfo.discount == 'reserve' ? 'V' : '',
            item.totalInfo.discount == 'warranty' ? 'V' : '',
            item.appInfo.appTech ? item.appInfo.appTech.techName : '',
            transferPay(item.paymentInfo, 'cash'),
            transferPay(item.paymentInfo, 'credit_card'),
            transferPay(item.paymentInfo, 'remit'),
            transferPay(item.paymentInfo, 'monthly'),
            transferPay(item.paymentInfo, 'gift_voucher'),
            transferPay(item.paymentInfo, 'gov_voucher'),
            transferName(item.repairTech),
            item.checkoutTech.techName,
            v.memo,
            item.totalInfo.taxID,
            item.totalInfo.company,
            item.totalInfo.receiver ? item.totalInfo.receiver : '',
            item.totalInfo.address,
            v.tireType == '新胎' ? '新胎' : v.fullSize.split('/').pop(),
            v.tireType == '新胎' ? '新' : 1,
            v.tireType == '新胎' ? '' : v.number,
            item.status == '作廢' ? `作廢 (${item.ticketMemo})` : '',
          ]);
        }
      });
      // 定位工資
      item.pAndPInfo.forEach((v) => {
        if (v.price && v.type) {
          data.push([
            item.ticketNo,
            item.ticketDate,
            item.shift,
            item.clientLicenseNo,
            item.clientInfo.clientName,
            item.clientInfo.clientPhone,
            `${v.type}-${v.content}`,
            1,
            v.price ? v.price : 0,
            v.price ? v.price : 0,
            '',
            '',
            '',
            '',
            '',
            item.totalInfo.discount == 'reserve' ? 'V' : '',
            item.totalInfo.discount == 'warranty' ? 'V' : '',
            item.appInfo.appTech ? item.appInfo.appTech.techName : '',
            transferPay(item.paymentInfo, 'cash'),
            transferPay(item.paymentInfo, 'credit_card'),
            transferPay(item.paymentInfo, 'remit'),
            transferPay(item.paymentInfo, 'monthly'),
            transferPay(item.paymentInfo, 'gift_voucher'),
            transferPay(item.paymentInfo, 'gov_voucher'),
            transferName(item.repairTech),
            item.checkoutTech.techName,
            v.memo,
            item.totalInfo.taxID,
            item.totalInfo.company,
            item.totalInfo.receiver ? item.totalInfo.receiver : '',
            item.totalInfo.address,
            '',
            '',
            '',
            item.status == '作廢' ? `作廢 (${item.ticketMemo})` : '',
          ]);
        }
      });
    }
    const title = [
      '單號',
      '日期',
      '班別',
      '車號',
      '姓名',
      '電話',
      '項目',
      '數量',
      '單價',
      '金額',
      '品牌',
      '廠商',
      '成本',
      '線上標價',
      '折扣',
      '預約',
      '保固',
      'APP',
      '現金',
      '刷卡',
      '匯款 ',
      '月結',
      '禮券',
      '消費卷',
      '維修師傅',
      '結帳師傅',
      '備註',
      '統編',
      '公司行號',
      '收件人',
      '發票地址',
      '尺寸',
      '胎',
      '編號',
      '作廢 (備註)',
    ];
    tab.range('A1:AI1').value([title]);
    tab
      .range(`A2:AI${data.length + 1}`)
      .value(data)
      .style({ horizontalAlignment: 'center', border: true });
    tab.column('A').width(17);
    tab.column('B').width(14);
    tab.column('C').width(6);
    tab.column('D').width(14);
    tab.column('E').width(10);
    tab.column('F').width(16);
    tab.column('G').width(20);
    tab.column('J').style('fill', 'FFC000');
    tab.cell('R1').style({ fontColor: 'FF0000' });
    tab.column('R').style({ fill: 'FFFF00' });
    tab.column('Y').width(25);
    tab.column('Z').width(25);
    tab.column('AA').width(70);
    tab.column('AB').width(25);
    tab.column('AC').width(25);
    tab.column('AD').width(25);
    tab.column('AE').width(25);
    tab.column('AF').width(10).style({ fill: 'FCD5B4' });
    tab.column('AG').width(6).style({ fill: 'CCFFFF' });
    tab.column('AH').width(14);
    tab.column('AI').width(14);
    tab.range('A1:AI1').style({
      horizontalAlignment: 'center',
      border: true,
      fontSize: 20,
      fill: '00B0F0',
      bold: true,
    });
    for (let i = 0; i < data.length; i++) {
      if (data[i][0].includes('員工')) {
        tab.row(i + 2).style({ fontColor: 'FF0000' });
        tab.cell(`J${i + 2}`).value(0);
      }
      if (data[i][8] == 0 || data[i][9] == 0) {
        tab.row(i + 2).style({ fill: 'C6EFCE' });
      }
      if (data[i][34].includes('作廢')) {
        tab.row(i + 2).style({ fill: 'FDE9D9' });
        tab.cell(`J${i + 2}`).value(0);
        tab.range(`K${i + 2}:AH${i + 2}`).clear();
      }
    }
    const out = await wb.outputAsync();
    let file = new Blob([out], { type: 'octet/stream' });
    return { filename, blob: file };
  };

  const handleDownload = async () => {
    const res = await getReportBlob();
    const link = document.createElement('a');
    link.download = res.filename;
    link.href = URL.createObjectURL(res.blob);
    link.click();
  };

  const createOneDateReport = async () => {
    if (!oneDate) return dispatch(openErrorSnackbar('錯誤！請先選擇日期！'));
    if (!selectShift) return dispatch(openErrorSnackbar('錯誤！請先選擇班別！'));
    if (!selectLocation) return dispatch(openErrorSnackbar('錯誤！請先選擇地點！'));
    const filename = `當日報表 - ${oneDate}.xlsx`;
    const dateObj = dayjs(oneDate);
    let wb = await XlsxPopulate.fromBlankAsync();
    wb.sheet(0).name('new');
    const tickets = [];
    try {
      const submit = {
        start_date: oneDate,
        end_date: oneDate,
        location: selectLocation,
        shift: selectShift,
      };
      const res = await axios.post(`${Api.Ticket.GetStatistics}`, submit, AxiosConfig.General);
      res.data.forEach((v) => {
        v.ticketDate = dayjs(v.date).format('YYYY-MM-DD');
        v.repairTech = JSON.parse(v.repairTech);
        v.checkoutTech = JSON.parse(v.checkoutTech);
        v.clientInfo = JSON.parse(v.clientInfo);
        v.tireInfo = JSON.parse(v.tireInfo);
        v.repairInfo = JSON.parse(v.repairInfo);
        v.pAndPInfo = JSON.parse(v.pAndPInfo);
        v.suggestInfo = JSON.parse(v.suggestInfo);
        v.appInfo = JSON.parse(v.appInfo);
        v.totalInfo = JSON.parse(v.totalInfo);
        v.paymentInfo = JSON.parse(v.paymentInfo);
        if (!v.ticketNo.includes('員工')) tickets.push(v);
      });
    } catch (error) {
      console.log(error);
      return dispatch(openErrorSnackbar('錯誤！請聯繫維護人員！'));
    }

    const tab1 = wb.addSheet(`${selectLocation}${selectShift}`);
    const fontStyle = { bold: true, border: true, fontFamily: 'Arial', fontSize: 12 };
    const centerStyle = { horizontalAlignment: 'center', verticalAlignment: 'center' };
    const leftStyle = { horizontalAlignment: 'left', verticalAlignment: 'center' };

    tab1.column('A').width(6).style(centerStyle);
    tab1.column('B').width(25).style(centerStyle);
    tab1.column('C').width(7).style(centerStyle);
    tab1.column('D').width(7).style(centerStyle);
    tab1.column('E').width(8).style(centerStyle);
    tab1.column('F').width(5).style(centerStyle);
    tab1.column('G').width(8).style(centerStyle);
    tab1.column('H').width(8).style(centerStyle);
    tab1.column('I').width(16).style(centerStyle);
    tab1.column('J').width(8).style(centerStyle);
    tab1.column('K').width(8).style(centerStyle);
    tab1.column('L').width(7).style(centerStyle);
    tab1.column('M').width(7).style(centerStyle);

    tab1.range('A1:F1').merged(true);
    tab1.range('G1:H1').merged(true);
    tab1.range('J1:K1').merged(true);
    tab1.range('L1:M1').merged(true);
    tab1.row(1).style({
      fontColor: '0606E6',
      fontSize: '25',
      bold: true,
      horizontalAlignment: 'center',
    });
    tab1.cell('A1').value(`輪胎大師 - ${selectLocation}館`);
    tab1.cell('G1').value(`${dateObj.get('year') - 1911}年`);
    tab1.cell('I1').value(`${dateObj.get('month') + 1}月`);
    tab1.cell('J1').value(`${dateObj.get('date')}日`);
    tab1.cell('L1').value(`${selectShift}`);

    tab1.range('C2:D2').merged(true);
    tab1.range('E2:F2').merged(true);
    tab1.range('J2:K2').merged(true);
    tab1.range('L2:M2').merged(true);
    tab1.cell('B2').value(`工單編號`).style(fontStyle);
    tab1.cell('C2').value(`車號`).style(fontStyle);
    tab1.cell('E2').value(`現金`).style(fontStyle);
    tab1
      .cell('G2')
      .value(`刷卡`)
      .style({ ...fontStyle, fontColor: 'FF0000' });
    tab1
      .cell('H2')
      .value(`匯款`)
      .style({ ...fontStyle, fontColor: 'FF0000' });
    tab1.cell('I2').value(`保固編號/金額`).style(fontStyle);
    tab1.cell('J2').value(`材料廠商`).style(fontStyle);
    tab1.cell('L2').value(`輪 胎`).style(fontStyle);
    tab1.range('B2:M2').style({ border: true });
    tab1.row(2).height(20);
    for (let i = 3; i < 20; i++) {
      tab1.row(i).height(25);
      tab1.range(`C${i}:D${i}`).merged(true);
      tab1.range(`J${i}:K${i}`).merged(true);
      tab1.cell(`C${i}`).value('-').style({ fontColor: 'FF0000' });
      tab1.cell(`F${i}`).style({ fill: 'FDE9D9' });
      tab1.cell(`I${i}`).value('/').style({ fill: 'FFEB9C', fontColor: 'FF0000' });
      tab1.cell(`L${i}`).style({ fontColor: '0606E6' });
      tab1.cell(`M${i}`).style({ fill: 'FDE9D9' });
    }
    tab1.range('B3:M19').style({ horizontalAlignment: 'center', ...fontStyle });

    tab1.range('B20:M20').merged(true);
    tab1
      .cell('B20')
      .value('Google評論數：      (內接班人員檢查.簽名）')
      .style({ ...fontStyle, ...leftStyle });
    tab1.row(20).height(20);

    for (let i = 21; i < 29; i++) {
      tab1.range(`D${i}:F${i}`).merged(true);
      tab1.range(`H${i}:I${i}`).merged(true);
      tab1.range(`L${i}:M${i}`).merged(true);
      tab1.cell(`B${i}`).value(i == 21 ? '日領' : '');
      tab1.cell(`C${i}`).value(i == 21 ? '金額' : '');
      tab1.cell(`D${i}`).value(i == 21 ? '廠商' : '');
      tab1.cell(`G${i}`).value(i == 21 ? '金額' : '');
      tab1.cell(`H${i}`).value(i == 21 ? '廠商' : '');
      tab1.cell(`J${i}`).value(i == 21 ? '金額' : '');
      tab1.row(i).height(i == 21 ? 20 : 25);
    }
    tab1.cell('K22').value('營收：').style(leftStyle);
    tab1.cell('K23').value('支出：').style(leftStyle);
    tab1.cell('K24').value('刷卡：').style(leftStyle);
    tab1.cell('K25').value('匯款：').style(leftStyle);
    tab1.cell('K26').value('月結：').style(leftStyle);
    tab1.cell('K27').value('現金：').style({ horizontalAlignment: 'left', fontColor: 'FF0000' });
    tab1.cell('K28').value('保固：').style({ horizontalAlignment: 'left', fill: 'FFEB9C' });
    tab1.range('K29:M29').merged(true);
    tab1
      .cell('K29')
      .value('總成本：')
      .style({ ...fontStyle, horizontalAlignment: 'left', fontColor: '800080' });

    tab1.range('B21:M29').style(fontStyle);
    tab1.range('B29:J29').merged(true);
    tab1
      .cell('B29')
      .value('上班人員簽名：')
      .style({
        ...fontStyle,
        fill: 'C6EFCE',
        fontColor: '008000',
        horizontalAlignment: 'left',
      });
    tab1.row(29).height(20);
    tab1.row(30).height(20);

    tab1.range('B30:M30').merged(true);
    tab1
      .cell('B30')
      .value(
        '新胎：       中古胎：       材料：       車資：       APP：       入庫：       稅％：       出保：',
      )
      .style({
        fontColor: '800080',
        horizontalAlignment: 'left',
        bold: true,
        fontFamily: 'Arial',
      });

    // 填入tickets
    const rows = [];
    const sortMoney = { credit_card: 0, remit: 0, monthly: 0 };
    let total = 0;

    const getPaymentMoney = (arr, method) => {
      const findOne = arr.find((v) => v.method == method);
      return findOne ? parseInt(findOne.money) : 0;
    };
    for (const ticket of tickets) {
      const oldTires = ticket.tireInfo.filter((el) => el.tireType == '中古胎');
      rows.push([
        ticket.ticketNo,
        ticket.clientInfo.licenseNo,
        '',
        getPaymentMoney(ticket.paymentInfo, 'cash') || 0,
        ticket.status == '作廢' ? '作廢' : '',
        getPaymentMoney(ticket.paymentInfo, 'credit_card') || '',
        getPaymentMoney(ticket.paymentInfo, 'remit') || '',
        '/',
        '',
        '',
        oldTires.length == 0 ? '' : oldTires.length,
        '',
      ]);
      sortMoney.credit_card += getPaymentMoney(ticket.paymentInfo, 'credit_card');
      sortMoney.remit += getPaymentMoney(ticket.paymentInfo, 'remit');
      sortMoney.monthly += getPaymentMoney(ticket.paymentInfo, 'monthly');
      if (ticket.status !== '作廢') total += parseInt(ticket.totalInfo.totalPrice);
    }
    for (let i = 0; i < 100; i++) {
      rows.push(['', '', '', '', '', '', '', '/', '', '', '', '']);
    }
    let pageOne = [];
    let pageTwo = [];
    let pageThree = [];
    if (rows.length <= 17) pageOne = [...rows];
    if (rows.length > 17 && rows.length <= 45) {
      pageOne = rows.slice(0, 17);
      pageTwo = rows.slice(17);
    }
    if (rows.length > 45) {
      pageOne = rows.slice(0, 17);
      pageTwo = rows.slice(17, 45);
      pageThree = rows.slice(45);
    }
    tab1.range(`B3:M${rows.length + 2}`).value(pageOne);
    tab1.cell('L24').value(sortMoney.credit_card);
    tab1.cell('L25').value(sortMoney.remit);
    tab1.cell('L26').value(sortMoney.monthly);
    tab1.cell('L22').value(total);
    wb.deleteSheet(0);

    if (pageTwo.length) {
      tab1.range('C31:D31').merged(true);
      tab1.range('E31:F31').merged(true);
      tab1.range('J31:K31').merged(true);
      tab1.range('L31:M31').merged(true);
      tab1.cell('B31').value(`工單編號`).style(fontStyle);
      tab1.cell('C31').value(`車號`).style(fontStyle);
      tab1.cell('E31').value(`現金`).style(fontStyle);
      tab1
        .cell('G31')
        .value(`刷卡`)
        .style({ ...fontStyle, fontColor: 'FF0000' });
      tab1
        .cell('H31')
        .value(`匯款`)
        .style({ ...fontStyle, fontColor: 'FF0000' });
      tab1.cell('I31').value(`保固編號/金額`).style(fontStyle);
      tab1.cell('J31').value(`材料廠商`).style(fontStyle);
      tab1.cell('L31').value(`輪 胎`).style(fontStyle);
      tab1.range('B31:M31').style({ border: true });
      tab1.row(31).height(20);
      for (let i = 32; i < 60; i++) {
        tab1.row(i).height(25);
        tab1.range(`C${i}:D${i}`).merged(true);
        tab1.range(`J${i}:K${i}`).merged(true);
        tab1.cell(`C${i}`).value('-').style({ fontColor: 'FF0000' });
        tab1.cell(`F${i}`).style({ fill: 'FDE9D9' });
        tab1.cell(`I${i}`).value('/').style({ fill: 'FFEB9C', fontColor: 'FF0000' });
        tab1.cell(`L${i}`).style({ fontColor: '0606E6' });
        tab1.cell(`M${i}`).style({ fill: 'FDE9D9' });
      }
      tab1.range('B32:M59').style({ horizontalAlignment: 'center', ...fontStyle });
      tab1.range('B60:M60').merged(true);
      tab1
        .cell('B60')
        .value(
          '新胎：       中古胎：       材料：       車資：       APP：       入庫：       稅％：       出保：',
        )
        .style({
          fontColor: '800080',
          horizontalAlignment: 'left',
          bold: true,
          fontFamily: 'Arial',
        });
      tab1.row(60).height(20);
    }

    if (pageThree.length) {
      tab1.range('C61:D61').merged(true);
      tab1.range('E61:F61').merged(true);
      tab1.range('J61:K61').merged(true);
      tab1.range('L61:M61').merged(true);
      tab1.cell('B61').value(`工單編號`).style(fontStyle);
      tab1.cell('C61').value(`車號`).style(fontStyle);
      tab1.cell('E61').value(`現金`).style(fontStyle);
      tab1
        .cell('G61')
        .value(`刷卡`)
        .style({ ...fontStyle, fontColor: 'FF0000' });
      tab1
        .cell('H61')
        .value(`匯款`)
        .style({ ...fontStyle, fontColor: 'FF0000' });
      tab1.cell('I61').value(`保固編號/金額`).style(fontStyle);
      tab1.cell('J61').value(`材料廠商`).style(fontStyle);
      tab1.cell('L61').value(`輪 胎`).style(fontStyle);
      tab1.range('B61:M61').style({ border: true });
      tab1.row(61).height(20);
      for (let i = 62; i < 90; i++) {
        tab1.row(i).height(25);
        tab1.range(`C${i}:D${i}`).merged(true);
        tab1.range(`J${i}:K${i}`).merged(true);
        tab1.cell(`C${i}`).value('-').style({ fontColor: 'FF0000' });
        tab1.cell(`F${i}`).style({ fill: 'FDE9D9' });
        tab1.cell(`I${i}`).value('/').style({ fill: 'FFEB9C', fontColor: 'FF0000' });
        tab1.cell(`L${i}`).style({ fontColor: '0606E6' });
        tab1.cell(`M${i}`).style({ fill: 'FDE9D9' });
      }
      tab1.range('B62:M89').style({ horizontalAlignment: 'center', ...fontStyle });
      tab1.range('B90:M90').merged(true);
      tab1
        .cell('B90')
        .value(
          '新胎：       中古胎：       材料：       車資：       APP：       入庫：       稅％：       出保：',
        )
        .style({
          fontColor: '800080',
          horizontalAlignment: 'left',
          bold: true,
          fontFamily: 'Arial',
        });
      tab1.row(90).height(20);
    }

    const out = await wb.outputAsync();
    let file = new Blob([out], { type: 'octet/stream' });
    return { filename, blob: file };
  };

  const handleExportOneDayReport = async () => {
    const res = await createOneDateReport();
    const link = document.createElement('a');
    link.download = res.filename;
    link.href = URL.createObjectURL(res.blob);
    link.click();
  };

  const handlePrintOneDayReport = async () => {
    if (!oneDate) return dispatch(openErrorSnackbar('錯誤！請先選擇日期！'));
    if (!selectShift) return dispatch(openErrorSnackbar('錯誤！請先選擇班別！'));
    if (!selectLocation) return dispatch(openErrorSnackbar('錯誤！請先選擇地點！'));
    try {
      const tickets = [];
      const dayTotal = { credit_card: 0, remit: 0, monthly: 0, total: 0 };
      const submit = {
        start_date: oneDate,
        end_date: oneDate,
        location: selectLocation,
        shift: selectShift,
      };
      const res = await axios.post(`${Api.Ticket.GetStatistics}`, submit, AxiosConfig.General);
      const getPaymentMoney = (arr, method) => {
        const findOne = arr.find((v) => v.method == method);
        return findOne ? parseInt(findOne.money) : 0;
      };
      res.data.forEach((v) => {
        if (!v.ticketNo.includes('員工')) {
          const clientInfo = JSON.parse(v.clientInfo);
          const paymentInfo = JSON.parse(v.paymentInfo);
          const totalInfo = JSON.parse(v.totalInfo);
          dayTotal.credit_card += getPaymentMoney(paymentInfo, 'credit_card');
          dayTotal.remit += getPaymentMoney(paymentInfo, 'remit');
          dayTotal.monthly += getPaymentMoney(paymentInfo, 'monthly');
          if (v.status !== '作廢') dayTotal.total += parseInt(totalInfo.totalPrice);
          const oldTires = JSON.parse(v.tireInfo).filter((el) => el.tireType == '中古胎');

          tickets.push({
            ticketNo: v.ticketNo,
            licenseNo: clientInfo ? clientInfo.licenseNo : '',
            cash: getPaymentMoney(paymentInfo, 'cash') || 0,
            credit_card: getPaymentMoney(paymentInfo, 'credit_card') || '',
            remit: getPaymentMoney(paymentInfo, 'remit') || '',
            tires: oldTires.length == 0 ? '' : oldTires.length,
            status: v.status,
          });
        }
      });

      let pages = 0;
      let maxRows = 0;
      let length = tickets.length;
      if (length <= 17) {
        pages = 1;
        maxRows = 17;
      } else if (length >= 18 && length <= 45) {
        pages = 2;
        maxRows = 45;
      } else {
        pages = 3;
        maxRows = 73;
      }
      for (let i = length; i < maxRows; i++) {
        tickets.push({
          ticketNo: '',
          licenseNo: '-',
          cash: '',
          credit_card: '',
          remit: '',
          status: '',
        });
      }
      setDayTickets({
        date: dayjs(oneDate),
        location: selectLocation,
        shift: selectShift,
        tickets,
        dayTotal,
        pages,
      });
      handlePrintOpen();
    } catch (error) {
      console.log(error);
      return dispatch(openErrorSnackbar('錯誤！請聯繫維護人員！'));
    }
  };

  return (
    <>
      <form onSubmit={startSearch}>
        <div className={classes.main}>
          <div className={classes.title}>{'當日報表'}</div>
          <div className={classes.row}>
            <DateField value={oneDate} onChange={(date) => setOneDate(date)} label="當日報表" />
            <ShiftField val={selectShift} valChange={(shift) => setSelectShift(shift)} />
            <LocationField
              val={selectLocation}
              valChange={(location) => setSelectLocation(location)}
            />
            <div>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CloudDownload />}
                onClick={handleExportOneDayReport}
              >
                {'匯出Excel'}
              </Button>
              <Button
                className={classes.printButton}
                variant="contained"
                size="large"
                startIcon={<Print />}
                onClick={handlePrintOneDayReport}
              >
                {'列印'}
              </Button>
            </div>
          </div>
          <div className={classes.divideline}></div>
          <div className={classes.title}>{'流水帳報表'}</div>
          <div className={classes.row}>
            <CheckboxLabels setLabelFunc={setLabels} />
          </div>
          {Object.values(labels).some((value) => value === true) && (
            <div className={classes.row}>
              {labels.start_date && (
                <DateField
                  value={search.start_date}
                  onChange={(start_date) => updateSearchArgs({ start_date })}
                  label="起始日期"
                />
              )}
              {labels.end_date && (
                <DateField
                  value={search.end_date}
                  onChange={(end_date) => updateSearchArgs({ end_date })}
                  label="結束日期"
                />
              )}
              {labels.location && (
                <LocationField
                  val={search.location}
                  valChange={(location) => updateSearchArgs({ location })}
                />
              )}
              {labels.shift && (
                <ShiftField val={search.shift} valChange={(shift) => updateSearchArgs({ shift })} />
              )}
            </div>
          )}
        </div>
        <div className={classes.searchButton}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            startIcon={<Search />}
          >
            {'報表查詢'}
          </Button>
        </div>
      </form>
      {loading && (
        <div className={classes.center}>
          <CircularProgress />
        </div>
      )}
      {!loading && !isEmpty(searchResult) && isArray(searchResult) && showResult ? (
        <div>
          <div className={classes.row}>
            <Button
              variant="contained"
              type="button"
              color="primary"
              size="large"
              startIcon={<CloudDownload />}
              onClick={handleDownload}
            >
              {'下載XLSX報表'}
            </Button>
            <br />
          </div>
          <StatisticsResultTable data={searchResult} />
        </div>
      ) : null}
      {dayTickets && openPrint ? (
        <StatisticsPrintModal
          modalOpen={openPrint}
          handleModalClose={handlePrintClose}
          ticketData={dayTickets}
        />
      ) : null}
    </>
  );
}
