const TabStyle = (theme) => ({
  content: {
    width: '100%',
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '15px 0px',
  },
  formControl: {
    minWidth: 120,
  },
  uploadButton: {
    padding: '10px 40px 20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  columnGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '90%',
    flexWrap: 'wrap',
  },
  column: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: 'auto',
    padding: '10px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      padding: '10px 0px 0px 10px',
    },
  },
  clientColumn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: 'auto',
    padding: '10px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px 0px 10px',
    },
  },
  appColumn: {
    width: '80%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
    height: 'auto',
    padding: '10px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      padding: '10px 0px 0px 10px',
    },
  },
  cardColumn: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  tableControlBtns: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: 'auto',
    margin: '10px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  selectBtnGroup: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: '10px 0px',
  },
  toggleColor: {
    '&.Mui-selected': {
      color: '#3f51b5',
      fontWeight: 'bold',
    },
    fontSize: '16px',
    width: '150px',
  },
  fieldTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '110px',
    height: '100%',
    fontSize: '18px',
    paddingRight: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      width: '100px',
    },
  },
  fieldText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    height: '100%',
    fontSize: '16px',
    fontWeight: 'bold',
    paddingLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  matchTech_app: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    height: '100%',
    fontSize: '16px',
    fontWeight: 'bold',
    paddingLeft: '20px',
    marginLeft: '110px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginLeft: '100px',
    },
  },
  matchTech: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    height: '100%',
    fontSize: '16px',
    fontWeight: 'bold',
    paddingLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  image: {
    width: 'auto',
    height: '140px',
    [theme.breakpoints.down('sm')]: {
      height: '120px',
      maxWidth: '100%',
      paddingRight: '15px',
    },
  },
  errorText: {
    padding: '10px 0px 10px',
    textAlign: 'center',
    fontSize: '18px',
    color: 'red',
  },
  title: {
    fontSize: '24px',
    textAlign: 'left',
    width: '90%',
    borderBottom: '1px solid gray',
    margin: '10px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      margin: '5px 0',
    },
  },
  addButton: {
    backgroundColor: '#2e7d32',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(46, 125, 50, 0.8)',
    },
  },
  smallAddBtn: {
    backgroundColor: '#2e7d32',
    color: 'white',
    margin: '0px 5px',
    '&:hover': {
      backgroundColor: 'rgba(46, 125, 50, 0.8)',
    },
  },
  smallRemoveBtn: {
    backgroundColor: '#d32f2f',
    color: 'white',
    margin: '0px 5px',
    '&:hover': {
      backgroundColor: 'rgba(211, 47, 47, 0.8)',
    },
  },
  headerCell: {
    fontSize: 14,
    width: 100,
    minWidth: 100,
    backgroundColor: theme.palette.grey[800],
    color: 'white',
    padding: '12px 20px 8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  headerCell__large: {
    fontSize: 14,
    width: 140,
    minWidth: 100,
    backgroundColor: theme.palette.grey[800],
    color: 'white',
    padding: '12px 20px 8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  headerCell__medium: {
    fontSize: 14,
    width: 80,
    backgroundColor: theme.palette.grey[800],
    color: 'white',
    padding: '10px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      padding: '4px !important',
    },
  },
  headerCell__small: {
    fontSize: 14,
    width: 40,
    backgroundColor: theme.palette.grey[800],
    color: 'white',
    padding: '10px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      padding: '4px !important',
    },
  },
  bodyCell: {
    fontSize: 12,
    padding: '4px 0px 0px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  footRow: {},
  footCell: {
    fontSize: 12,
    padding: '4px 0px 0px',
    height: '60px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  cellText: {
    margin: '5px',
  },
  multiTextField: {
    width: '100%',
    marginTop: '20px',
  },
  detailContainer: {
    width: '90%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
    height: 'auto',
    padding: '10px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '10px 0px 0px 10px',
    },
  },
  detailTitle: {
    padding: '3px 0px',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  detailContent: {
    padding: '3px 0px',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
});

export default TabStyle;
