const MainStyle = (theme) => ({
  mainContent: {
    minHeight: '100vh',
  },
  root: {
    padding: '5px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  paper: {
    padding: '10px',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 5px',
    },
    marginTop: '10px',
    marginBottom: '10px',
  },
  title: {
    padding: '10px 0px 10px',
    fontSize: '32px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  card: {
    marginBottom: '20px',
    border: '1px solid rgba(128, 128, 128, 0.2)',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 25%), 0px 4px 5px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 15%)',
  },
  backdrop: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: 135,
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      top: 75,
    },
  },
  backdropText: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginTop: 250,
  },
  secondaryContainer: {
    backgroundColor: '#8b8b8b94',
  },
  noPadding: {
    padding: 0,
  },
  toolbarSecondary: {
    height: '75px',
    padding: '0',
    justifyContent: 'center',
    alignItems: 'center',
    overflowX: 'auto',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  toolbarLink: {
    padding: '10px 24px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 20px',
    backgroundColor: '#2e7d32',
    fontSize: '16px',
    lineHeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      margin: '0px 10px',
    },
  },
});

export default MainStyle;
