import { TICKET_GET_DETAIL, TICKET_GENERATE_BY_PREPAY, TICKET_CLEAR } from '../actionTypes';

const ticketDefault = {
  ticketType: '',
  signImage: '',
  carMark: '',
  basicInfo: {},
  clientInfo: {},
  tireInfo: [],
  repairInfo: [],
  pAndPInfo: [],
  suggestInfo: '',
  appInfo: {},
  totalInfo: {},
  paymentInfo: [],
};

const TicketReducer = (state = ticketDefault, action) => {
  switch (action.type) {
    case TICKET_GET_DETAIL:
      return {
        ...state,
      };
    case TICKET_GENERATE_BY_PREPAY:
      return {
        ...state,
        ticketType: 'Common',
        signImage: '',
        carMark: '',
        basicInfo: action.payload.basicInfo,
        clientInfo: action.payload.clientInfo,
        tireInfo: action.payload.tireInfo,
        repairInfo: action.payload.repairInfo,
        pAndPInfo: action.payload.pAndPInfo,
        suggestInfo: action.payload.suggestInfo,
        appInfo: action.payload.appInfo,
        totalInfo: action.payload.totalInfo,
      };
    case TICKET_CLEAR:
      return {
        ticketType: '',
        signImage: '',
        carMark: '',
        basicInfo: {},
        clientInfo: {},
        tireInfo: [],
        repairInfo: [],
        pAndPInfo: [],
        suggestInfo: '',
        appInfo: {},
        totalInfo: {},
        paymentInfo: [],
      };
    default:
      return state;
  }
};

export default TicketReducer;
