import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CircularProgress, FormControlLabel, Checkbox } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import InputTextField from '../../components/Ticket/InputTextField';
import styles from './TabStyle';

const useStyles = makeStyles(styles);

const InvoiceTab = (props) => {
  const classes = useStyles();
  const { ticketData, totalInfo, setTicketData, isInvoice } = props;
  const [alignment, setAlignment] = useState('');
  const handleToggleChange = (event, newAlignment) => {
    newAlignment ? setAlignment(newAlignment) : setAlignment(isInvoice);
  };

  const updateTotalInfo = useCallback(
    (v) => {
      let newData = { ...ticketData };
      newData.totalInfo = { ...totalInfo, ...v };
      setTicketData(newData);
    },
    [ticketData],
  );

  useEffect(() => {
    // 不印工單時，不加上營業稅
    if (totalInfo.selfGet) updateTotalInfo({ receiver: '', address: '' });
  }, [totalInfo.selfGet]);

  return (
    <>
      {ticketData && totalInfo ? (
        <div className={classes.content}>
          <div className={classes.main}>
            <div className={classes.title}>{'發票統編'}</div>
            <div className={classes.column}>
              <div className={classes.selectBtnGroup}>
                <ToggleButtonGroup
                  color="success"
                  value={alignment}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Platform"
                >
                  <ToggleButton
                    className={classes.toggleColor}
                    value="需要發票"
                    onClick={() => updateTotalInfo({ isInvoice: '需要發票' }, 'type')}
                  >
                    需要發票
                  </ToggleButton>
                  <ToggleButton
                    className={classes.toggleColor}
                    value="不需要發票"
                    onClick={() => updateTotalInfo({ isInvoice: '不需要發票' }, 'type')}
                  >
                    不需要發票
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            {isInvoice === '需要發票' ? (
              <>
                <div className={classes.appColumn}>
                  <div className={classes.fieldTitle}>{'統編：'}</div>
                  <InputTextField
                    val={totalInfo.taxID}
                    valChange={(taxID) => updateTotalInfo({ taxID })}
                    label="統編"
                  />
                </div>
                <div className={classes.appColumn}>
                  <div className={classes.fieldTitle}>{'公司行號：'}</div>
                  <InputTextField
                    val={totalInfo.company}
                    valChange={(company) => updateTotalInfo({ company })}
                    label="公司行號"
                  />
                </div>
                <div className={classes.appColumn}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={totalInfo.selfGet}
                        checked={totalInfo.selfGet}
                        onChange={(e) => updateTotalInfo({ selfGet: e.target.checked })}
                        name="selfGet"
                        color="primary"
                      />
                    }
                    label="自取發票"
                  />
                </div>
                {!totalInfo.selfGet ? (
                  <>
                    <div className={classes.appColumn}>
                      <div className={classes.fieldTitle}>{'收件人：'}</div>
                      <InputTextField
                        val={totalInfo.receiver}
                        valChange={(receiver) => updateTotalInfo({ receiver })}
                        label="收件人"
                      />
                    </div>
                    <div className={classes.appColumn}>
                      <div className={classes.fieldTitle}>{'發票地址：'}</div>
                      <InputTextField
                        val={totalInfo.address}
                        valChange={(address) => updateTotalInfo({ address })}
                        label="發票地址"
                      />
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative', marginLeft: '50%', left: '-20px' }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

InvoiceTab.propTypes = {
  ticketData: PropTypes.object,
  totalInfo: PropTypes.object,
  setTicketData: PropTypes.func,
  isInvoice: PropTypes.string,
};

export default InvoiceTab;
