import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@material-ui/core';
import { ListAlt, Print } from '@material-ui/icons';
import TicketDetailModal from '../../components/Ticket/TicketDetailModal';
import TicketPrintModal from '../../components/Ticket/TicketPrintModal';
import styles from './searchResultTableStyle';
import { Scrollbars } from 'rc-scrollbars';
import dayjs from 'dayjs';

Date.prototype.yyyymmdd = function () {
  var mm = this.getMonth() + 1;
  var dd = this.getDate();
  return [this.getFullYear(), '-', (mm > 9 ? '' : '0') + mm, '-', (dd > 9 ? '' : '0') + dd].join(
    '',
  );
};

const useStyles = makeStyles(styles);

const SearchResultTable = (props) => {
  const classes = useStyles();
  const userStatus = useSelector((state) => state.user);
  if (isEmpty(userStatus)) {
    return null;
  }
  const { result } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [clickTicketData, setClickTicketData] = useState(null);

  const viewDetail = (value) => {
    handleOpen();
    setClickTicketData({ ...value });
  };
  const printTicket = (value) => {
    handlePrintOpen();
    setClickTicketData({ ...value });
  };
  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);
  const handlePrintOpen = () => setOpenPrint(true);
  const handlePrintClose = () => setOpenPrint(false);

  const columns = useMemo(() => {
    return [
      {
        field: 'detail',
        headerName: '詳細',
        width: 70,
        sortable: false,
        editable: false,
        disableClickEventBubbling: true,
        renderCell: function cell(params) {
          return (
            <IconButton
              disabled={userStatus.info.authority === 2 || userStatus.info.authority === 3}
              style={{ margin: 0 }}
              onClick={() => viewDetail(params.row)}
            >
              <ListAlt />
            </IconButton>
          );
        },
      },
      {
        field: 'print',
        headerName: '列印',
        width: 70,
        sortable: false,
        editable: false,
        disableClickEventBubbling: true,
        renderCell: function cell(params) {
          return (
            <IconButton style={{ margin: 0 }} onClick={() => printTicket(params.row)}>
              <Print />
            </IconButton>
          );
        },
      },
      {
        field: 'ticketDate',
        headerName: '工單日期',
        width: 120,
        editable: false,
      },
      {
        field: 'ticketNo',
        headerName: '工單號碼',
        width: 140,
        editable: false,
        sortable: false,
      },
      {
        field: 'location',
        headerName: '地點',
        width: 75,
        editable: false,
        sortable: false,
      },
      {
        field: 'shift',
        headerName: '班別',
        width: 75,
        editable: false,
        sortable: false,
      },
      {
        field: 'clientLicenseNo',
        headerName: '客戶車牌',
        width: 100,
        editable: false,
        sortable: false,
      },
      {
        field: 'transType',
        headerName: '類型',
        width: 75,
        editable: false,
        sortable: false,
      },
      {
        field: 'status',
        headerName: '狀態',
        width: 100,
        editable: false,
        sortable: false,
      },
      {
        field: 'ticketMemo',
        headerName: '作廢原因',
        width: 100,
        editable: false,
        sortable: false,
      },
    ];
  }, []);

  const rows = result.map((row, index) => {
    return {
      id: index,
      ticketDate: dayjs(row.date).format('YYYY-MM-DD'),
      ticketNo: row.ticketNo,
      location: row.location,
      shift: row.shift,
      clientLicenseNo: row.clientLicenseNo,
      techName: row.checkoutTech.techName,
      transType:
        row.ticketType == 'Prepay' ? '預付' : row.ticketNo.includes('員工') ? '員工' : '一般',
      basicInfo: {
        ticketNo: row.ticketNo,
        date: dayjs(row.date).format('YYYY-MM-DD'),
        checkoutTechID: row.checkoutTech.techID,
        checkoutTechName: row.checkoutTech.techName,
        repairTech: [...row.repairTech],
        location: row.location,
        shifts: row.shift,
        isEmployee: row.ticketNo.includes('員工'),
      },
      ...row,
    };
  });

  return (
    <>
      <div className={classes.table}>
        <Scrollbars
          autoHeight="true"
          autoHeightMax={365}
          autoHide="true"
          autoHideTimeout={1000}
          autoHideDuration={200}
          universal
          onScroll={(e) => {
            e.stopPropagation();
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu={true}
            autoHeight
          />
        </Scrollbars>
      </div>
      {clickTicketData && openDialog ? (
        <TicketDetailModal
          modalOpen={openDialog}
          handleModalClose={handleClose}
          ticketData={clickTicketData}
        />
      ) : null}
      {clickTicketData && openPrint ? (
        <TicketPrintModal
          modalOpen={openPrint}
          handleModalClose={handlePrintClose}
          ticketData={clickTicketData}
        />
      ) : null}
    </>
  );
};

SearchResultTable.propTypes = {
  result: PropTypes.array,
  refresh: PropTypes.func,
};

export default SearchResultTable;
