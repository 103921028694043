import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import styles from './statisticsResultTableStyle';
import { Scrollbars } from 'rc-scrollbars';

const useStyles = makeStyles(styles);

const StatisticsResultTable = (props) => {
  const classes = useStyles();
  const { data } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [scrollHeight, setScrollHeight] = useState(310);
  const tableRef = useRef();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const tableHeight = tableRef.current.clientHeight;
    if (rowsPerPage === 5) {
      setScrollHeight(310);
    } else {
      setScrollHeight(tableHeight);
    }
  }, [data, rowsPerPage]);

  return (
    <div className={classes.table}>
      <TableContainer component={Paper} elevation={4} style={{ overflow: 'hidden' }}>
        <Scrollbars
          style={{ height: scrollHeight }}
          autoHide="true"
          autoHideTimeout={1000}
          autoHideDuration={200}
          universal
          onScroll={(e) => {
            e.stopPropagation();
          }}
        >
          <Table ref={tableRef}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell} align="center">
                  工單日期
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  工單號碼
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  地點
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  班別
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  客戶車牌
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  結帳技師
                </TableCell>
                <TableCell className={classes.headerCell} align="center">
                  金額
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(data) &&
                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((v) => (
                  <TableRow key={v.id}>
                    <TableCell className={classes.bodyCell} align="center">
                      {v.ticketDate}
                    </TableCell>
                    <TableCell className={classes.bodyCell} align="center">
                      {v.ticketNo}
                    </TableCell>
                    <TableCell className={classes.bodyCell} align="center">
                      {v.location}
                    </TableCell>
                    <TableCell className={classes.bodyCell} align="center">
                      {v.shift}
                    </TableCell>
                    <TableCell className={classes.bodyCell} align="center">
                      {v.clientLicenseNo}
                    </TableCell>
                    <TableCell className={classes.bodyCell} align="center">
                      {v.checkoutTech.techName}
                    </TableCell>
                    <TableCell className={classes.bodyCell} align="center">
                      {v.totalInfo.totalPrice}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Scrollbars>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="每頁"
        />
      </TableContainer>
    </div>
  );
};

StatisticsResultTable.propTypes = {
  data: PropTypes.array,
};

export default StatisticsResultTable;
