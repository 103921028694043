import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
} from '@material-ui/core';
import { Warning, Add, Remove } from '@material-ui/icons';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Api, AxiosConfig } from 'GlobalDefine';

// import { useDispatch } from 'react-redux';
// import { openErrorSnackbar } from 'redux/actions';
import styles from './TabStyle';

const useStyles = makeStyles(styles);

const OldTireTab = (props) => {
  const classes = useStyles();
  const { ticketData, tireInfo, setTicketData } = props;
  // const dispatch = useDispatch();
  const tableRef = useRef();
  const [isSearch, setIsSearch] = useState(false);

  const updateTireInfo = useCallback(
    (v, updIndex) => {
      let newData = { ...ticketData };
      const currentItem = tireInfo.find((el) => el.index == updIndex);
      const currentIndex = tireInfo.findIndex((el) => el.index == updIndex);
      newData.tireInfo[currentIndex] = { ...currentItem, ...v };
      setTicketData(newData);
      const currentUpdate = Object.keys(v)[0];
      if (currentUpdate == 'number') getTireInfo(v.number, updIndex);
      if (currentUpdate == 'sell' && currentItem.price) {
        v.sell < currentItem.price * 0.8 * 100
          ? updateTireInfo({ warn: true }, updIndex)
          : updateTireInfo({ warn: false }, updIndex);
      }
    },
    [ticketData],
  );
  const setSizeEmpty = (index) => updateTireInfo({ fullSize: '', price: '', brand: '' }, index);
  const getTireInfo = async (id, index) => {
    if (id == '') return setSizeEmpty(index);
    try {
      setIsSearch(true);
      const res = await axios.get(`${Api.Tires.GetTireById}/${id}`, AxiosConfig.General);
      if (res.data) {
        const info = res.data[0];
        if (info.status !== '現貨') {
          Swal.fire({ title: `${id}已無庫存`, icon: 'warning' });
          updateTireInfo({ number: '' }, index);
        } else {
          updateTireInfo(
            {
              fullSize: `${info.width}/${info.height}/${info.size}`,
              price: info.price,
              brand: info.brand,
              firm: info.firm,
            },
            index,
          );
        }
      } else {
        setSizeEmpty(index);
      }
    } catch (error) {
      console.log('err:', error);
      setSizeEmpty(index);
    }
    setIsSearch(false);
  };

  const addBlankRow = () => {
    let newTicketData = { ...ticketData };
    let last = [...tireInfo].pop();
    newTicketData.tireInfo = [
      ...tireInfo,
      {
        index: last ? last.index + 1 : 0,
        tireType: '中古胎',
        number: '',
        fullSize: '',
        price: '',
        sell: '',
        memo: '',
        brand: '',
        amount: 1,
        warn: '',
      },
    ];
    setTicketData(newTicketData);
  };

  const removeLastRow = () => {
    let newTicketData = { ...ticketData };
    const oldTires = [...tireInfo].filter((el) => el.tireType == '中古胎');
    let last = [...oldTires].pop();
    newTicketData.tireInfo = tireInfo.filter((el) => el.index !== last.index);
    setTicketData(newTicketData);
  };

  useEffect(() => {}, []);

  return (
    <>
      {ticketData && tireInfo ? (
        <div className={classes.content}>
          <div className={classes.main}>
            <div className={classes.title}>{'中古胎更換項目'}</div>
            <div className={classes.cardColumn}>
              <TableContainer component={Paper} elevation={4} style={{ marginTop: '20px' }}>
                <Table ref={tableRef}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.headerCell} align="center">
                        編號
                      </TableCell>
                      <TableCell className={classes.headerCell__large} align="center">
                        尺寸
                      </TableCell>
                      <TableCell className={classes.headerCell__medium} align="center">
                        庫位
                      </TableCell>
                      <TableCell className={classes.headerCell__medium} align="center">
                        售價
                      </TableCell>
                      <TableCell className={classes.headerCell__medium} align="center">
                        備註
                      </TableCell>
                      <TableCell className={classes.headerCell__small} align="center" size="small">
                        警示
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tireInfo
                      .filter((el) => el.tireType == '中古胎')
                      .map((v, i) => (
                        <TableRow key={i} className={classes.bodyRow}>
                          <TableCell className={classes.bodyCell} align="center">
                            <TextField
                              className={classes.cellText}
                              variant="outlined"
                              size="small"
                              value={v.number}
                              onChange={(e) => updateTireInfo({ number: e.target.value }, v.index)}
                            />
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="center">
                            <TextField
                              className={classes.cellText}
                              variant="outlined"
                              size="small"
                              value={v.fullSize}
                              onChange={(e) =>
                                updateTireInfo({ fullSize: e.target.value }, v.index)
                              }
                              multiline
                            />
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="center">
                            <TextField
                              className={classes.cellText}
                              variant="outlined"
                              size="small"
                              value={v.price}
                              disabled
                            />
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="center">
                            <TextField
                              className={classes.cellText}
                              variant="outlined"
                              size="small"
                              value={v.sell}
                              onChange={(e) => updateTireInfo({ sell: e.target.value }, v.index)}
                            />
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="center">
                            <TextField
                              className={classes.cellText}
                              variant="outlined"
                              size="small"
                              value={v.memo}
                              onChange={(e) => updateTireInfo({ memo: e.target.value }, v.index)}
                              multiline
                            />
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="center">
                            <Warning
                              fontSize="small"
                              color="secondary"
                              style={v.warn ? { display: 'inline-block' } : { display: 'none' }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className={classes.tableControlBtns}>
              <Grid container spacing={2}>
                <Grid item md={5} xs={5} align="center">
                  <IconButton className={classes.smallRemoveBtn} onClick={removeLastRow}>
                    <Remove fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item md={2} xs={2} align="center">
                  <CircularProgress
                    style={isSearch ? { display: 'inline-block' } : { display: 'none' }}
                  />
                </Grid>
                <Grid item md={5} xs={5} align="center">
                  <IconButton className={classes.smallAddBtn} onClick={addBlankRow}>
                    <Add fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative', marginLeft: '50%', left: '-20px' }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

OldTireTab.propTypes = {
  ticketData: PropTypes.object,
  tireInfo: PropTypes.array,
  setTicketData: PropTypes.func,
};

export default OldTireTab;
